import { Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CarfixInput from "../commonComponents/CarfixInput";
import { useDispatch } from "react-redux";
import { updateOrderData } from "../../redux-slices/editOrderDataSlice";

export const HeadingwithData = ({
  heading,
  data,
  job_id,
  editOrderData = false,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [description, setDescription] = useState(data);

  const onChange = (e) => {
    const value = e.target.value;
    setDescription(value);
    dispatch(
      updateOrderData({ job_id, index: "job", key: "description", value })
    );
  };
  return (
    <div className="my-2 w-11/12">
      <Typography
        className="text-sm font-medium mb-1"
        style={{ color: theme.palette.orange.main }}
      >
        {heading}
      </Typography>
      {editOrderData ? (
        <CarfixInput
          className="w-full border-gray-200 border rounded-md"
          onChange={onChange}
          multiline
          rows={4}
          value={description}
        />
      ) : (
        <Typography
          className="text-sm font-normal text-justify"
          style={{ color: theme.palette.blue.main }}
        >
          {description}
        </Typography>
      )}
    </div>
  );
};
