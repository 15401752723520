import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import closeImg from "../../assets/images/close.svg";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import {
  closeMechanicSelectModal,
  setReassignFalse,
} from "../../redux-slices/modalSlice";
import { assignJobToMechanic } from "../../redux-slices/jobsSlice";
import { useTheme } from "@emotion/react";
import { getMechanics } from "../../redux-slices/usersSlice";

const MechanicSelectModal = ({ fetchData }) => {
  const { mechanicSelectModalOpen, modalJobId } = useSelector(
    (state) => state.modals
  );

  const { reassignVal } = useSelector((state) => state.modals);
  const theme = useTheme();
  const [selectedMechanic, setSelectedMechanic] = useState();
  const [mechanics, setMechanics] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mechanicSelectModalOpen) return;
    dispatch(getMechanics()).then((res) => {
      setMechanics(res.payload);
      setSelectedMechanic(res.payload[0].id);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mechanicSelectModalOpen]);

  const handleChange = (event) => {
    setSelectedMechanic(event.target.value);
  };

  const handleClose = () => {
    dispatch(setReassignFalse());
    dispatch(closeMechanicSelectModal());
  };

  const handleAssign = () => {
    dispatch(
      assignJobToMechanic({
        data: {
          id: modalJobId,
          reassignVal,
          mechanicId: selectedMechanic,
        },
        onSuccess: () => {
          fetchData();
          dispatch(setReassignFalse());
          dispatch(closeMechanicSelectModal());
        },
      })
    );
  };

  return (
    <Modal open={mechanicSelectModalOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
        className="rounded-3xl w-1/2 p-8"
      >
        <div className="flex justify-between">
          <Typography className="text-3xl font-bold">
            Assign Mechanic
          </Typography>
          <img
            src={closeImg}
            alt="Close"
            className="cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="mt-8">
          <Select
            className="rounded-lg w-2/5"
            value={selectedMechanic ? selectedMechanic : "Select Mechanic"}
            onChange={handleChange}
            IconComponent={KeyboardArrowDownIcon}
          >
            {Object.values(mechanics)
              .filter((mechanic) => typeof mechanic === "object")
              .map((mechanic) => (
                <MenuItem key={mechanic?.id} value={mechanic?.id}>
                  {mechanic?.name}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div className="flex justify-end mt-8">
          <Button
            className="w-60 md:w-36 h-10 text-white mt-7 md:mr-5 rounded-lg text-base"
            style={{
              background: theme.palette.orange.main,
              textTransform: "none",
            }}
            onClick={handleAssign}
          >
            {reassignVal ? "Reassign" : "Assign"}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default MechanicSelectModal;
