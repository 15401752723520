import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import ConfirmDialog from "../commonComponents/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  assignJobToDriver,
  assignJobToSupervisor,
  cancelJob,
  cancelPartsOrder,
  markJobAsComplete,
  approveJob,
  updateJobData,
} from "../../redux-slices/jobsSlice";
import {
  closeJobModal,
  openInvoiceNumberModal,
  openMechanicSelectModal,
  setReassignFalse,
  setReassignTrue,
} from "../../redux-slices/modalSlice";
import { downloadInvoice } from "../../redux-slices/invoiceSlice";
import { resetOrderData } from "../../redux-slices/editOrderDataSlice";
import { useNavigate } from "react-router";

const AdminButtons = ({
  remarks,
  submitRemarks,
  editOrderData,
  setEditOrderData,
  fetchData,
  fetchJobData,
  setOrderEdit,
  setLoading,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { hasUpdatedData } = useSelector((state) => state.editOrderData);
  const job = useSelector((state) => state.jobs.job);

  const [text, setText] = useState("");
  const [boldText, setBoldText] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const id = job?.id;
  const status = job?.status;

  const handleCloseDontAgree = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    handleCloseDontAgree();
    switch (selectedId) {
      case 0:
        dispatch(
          assignJobToDriver({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 3:
        dispatch(
          approveJob({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 4:
        dispatch(
          cancelPartsOrder({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 5:
        dispatch(
          cancelJob({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 7:
        dispatch(
          assignJobToSupervisor({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 10:
        saveData();
        dispatch(
          markJobAsComplete({
            invoice_id: job?.invoice_number,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const adminButtons = [
    {
      id: 6,
      title: "View Logs",
      show: true,
    },
    {
      id: 9,
      title: "Invoice",
      show: status === "to-be-invoiced",
    },
    {
      id: 0,
      title: "To Driver",
      show:
        status === "new-order" ||
        status === "to-be-assigned" ||
        status === "awaiting-mechanic" ||
        status === "awaiting-review" ||
        status === "to-be-invoiced",
    },
    {
      id: 1,
      title: "To Mechanic",
      show: status === "awaiting-mechanic",
    },
    {
      id: 2,
      title: "Reassign",
      show:
        status === "awaiting-review" ||
        status === "to-be-invoiced" ||
        status === "in-progress" ||
        status === "reassigned",
    },
    {
      id: 3,
      title: "Approve",
      show: status === "awaiting-review",
    },
    {
      id: 4,
      title: "Cancel Parts Order",
      color: "red",
      show: status === "awaiting-parts",
    },
    {
      id: 7,
      title: "To Garage",
      show: status === "new-order" || status === "to-be-assigned",
    },
    {
      id: 8,
      title: "Download Invoice",
      show: status === "invoiced" || status === "completed",
    },
    {
      id: 10,
      title: "Mark as Complete",
      show: status === "invoiced",
    },
    {
      id: 11,
      title: "Edit Invoice",
      show: status === "invoiced",
    },
    {
      id: 5,
      title: "Cancel Order",
      color: "red",
      show: status !== "completed" && status !== "cancelled",
    },
  ];

  const editOrderButtons = [
    {
      id: 0,
      title: "Save Changes",
      color: "#f3a366", //Orange
    },
    {
      id: 1,
      title: "Cancel Changes",
      color: "#fb7d7d", //Red
    },
  ];

  const handleEditOrderButtons = (item) => {
    switch (item.id) {
      case 0:
        saveData();
        setLoading(true);
        setEditOrderData(false);
        break;
      case 1:
        console.log("here");
        dispatch(resetOrderData());
        setEditOrderData(false);
        setOrderEdit((val) => !val);
        break;
      default:
        break;
    }
  };

  const handleClickOpen = (item) => {
    switch (item.id) {
      case 0:
        saveData();
        setSelectedId(item.id);
        setText("");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 1:
        saveData();
        setSelectedId(item.id);
        dispatch(setReassignFalse());
        dispatch(openMechanicSelectModal());
        dispatch(closeJobModal());
        break;
      case 2:
        saveData();
        setSelectedId(item.id);
        dispatch(setReassignTrue());
        dispatch(openMechanicSelectModal());
        dispatch(closeJobModal());
        break;
      case 3:
        saveData();
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 4:
        saveData();
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 5:
        saveData();
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 6:
        saveData();
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/logs/${id}`, {
          state: { job_id: id },
        });
        break;
      case 7:
        saveData();
        setSelectedId(item.id);
        setText("");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 8:
        saveData();
        dispatch(
          downloadInvoice({
            invoice_id: job?.invoice_number,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 9:
        saveData();
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/invoice/${id}`);
        break;
      case 10:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        setOpenConfirmDialog(true);
        break;
      case 11:
        saveData();
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/invoice/edit/${id}`);
        break;
      default:
        break;
    }
  };

  const saveData = () => {
    if (editOrderData) {
      setEditOrderData(false);
      if (hasUpdatedData) {
        dispatch(
          updateJobData({
            onSuccess: () => fetchJobData(setLoading),
          })
        );
      }
    }
    if (remarks) {
      submitRemarks();
    }
  };

  return (
    <>
      {editOrderData
        ? editOrderButtons.map((item) => (
            <Button
              key={item.id}
              className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base md:w-44`}
              style={{
                backgroundColor: item.color,
                textTransform: "none",
              }}
              onClick={() => handleEditOrderButtons(item)}
            >
              {item.title}
            </Button>
          ))
        : adminButtons.map((item) => (
            <Button
              key={item.id}
              className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base ${
                item.show ? "block" : "hidden"
              }
          ${
            item.id === 4 || item.id === 8 || item.id === 3 || item.id === 10
              ? "md:w-44"
              : "md:w-36"
          }`}
              style={{
                background:
                  item.color === "red"
                    ? theme.palette.red.main
                    : theme.palette.orange.main,
                textTransform: "none",
              }}
              onClick={() => handleClickOpen(item)}
            >
              {item.title}
            </Button>
          ))}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseDontAgree}
        onConfirm={handleConfirm}
        text={text}
        boldText={boldText}
      />
    </>
  );
};

export default AdminButtons;
