import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getVehicleNames = createAsyncThunk(
  "jobsSlice/getPdf",
  async ({ inputString }, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-vehicle-names?inputString=${inputString}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const vehicleSlice = createSlice({
  name: "vehicleSlice",
  initialState: {},
  reducers: {},
});

// eslint-disable-next-line no-empty-pattern
export const {} = vehicleSlice.actions;

export default vehicleSlice.reducer;
