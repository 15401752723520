import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import closeImg from "../../assets/images/close.svg";
import CarfixInput from "../commonComponents/CarfixInput";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { createService, updateService } from "../../redux-slices/servicesSlice";

const ServiceDialog = ({ open, setOpen, heading, service, getServices }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");

  useEffect(() => {
    if (service) {
      setName(service.name || "");
      setDescription(service.description || "");
      setUnitPrice(service.unit_price || "");
    }
  }, [open, service]);

  const onClose = () => {
    setOpen(false);
    setName("");
    setDescription("");
    setUnitPrice("");
  };

  const handleSubmit = () => {
    if (heading === "Edit Service") {
      if (
        name !== service?.name ||
        description !== service?.description ||
        unitPrice !== service?.unit_price
      ) {
        const data = {
          id: service.id,
          name,
          description,
          unit_price: parseFloat(unitPrice),
        };
        updateServiceData(data);
      }
      onClose();
    } else {
      if (name && unitPrice) {
        const data = {
          name,
          description,
          unit_price: parseFloat(unitPrice),
        };
        dispatch(createService({ data, onSuccess: () => getServices() }));
        onClose();
      } else {
        alert("Please fill all required fields (Name and Unit Price)");
      }
    }
  };

  const updateServiceData = (data) => {
    dispatch(
      updateService({
        data,
        onSuccess: () => {
          getServices();
        },
      })
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 20,
        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <Typography
            className="text-3xl font-bold"
            style={{ color: theme.palette.blue.main }}
          >
            {heading}
          </Typography>
          <img
            src={closeImg}
            alt="Close Dialog"
            className="cursor-pointer"
            onClick={onClose}
          />
        </div>
      </DialogTitle>
      <DialogContent className="mt-2">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Name:
            </Typography>
            <CarfixInput
              className="w-full"
              type="text"
              value={name}
              onChange={(event) => setName(event.target.value)}
              placeholder="Enter Service Name"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Description:
            </Typography>
            <CarfixInput
              className="w-full"
              type="text"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
              placeholder="Enter Description (Optional)"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              className="mb-2 font-bold text-base"
              style={{ color: theme.palette.blue.main }}
            >
              Unit Price:
            </Typography>
            <CarfixInput
              className="w-full"
              type="number"
              value={unitPrice}
              onChange={(event) => setUnitPrice(event.target.value)}
              placeholder="Enter Unit Price"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
          style={{
            textTransform: "none",
            background: theme.palette.orange.main,
          }}
          onClick={handleSubmit}
        >
          {heading === "Edit Service" ? "Save" : "Add Service"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceDialog;
