import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { OrderData } from "../modalComponents/OrderData";
import { HeadingwithData } from "../modalComponents/HeadingwithData";
import { MediaWithHeading } from "../modalComponents/MediaWithHeading";
import { OrderRemarks } from "../modalComponents/OrderRemarks";
import { AddRemarks } from "../modalComponents/AddRemarks";
import ShowMedia from "../modalComponents/ShowMedia";
import { useDispatch, useSelector } from "react-redux";
import { completedByMechanic } from "../../redux-slices/jobsSlice";
import { handleMediaSubmit, handleRemarksSubmit } from "../../utils/helpers";
import { AdminModalTable } from "../admin/AdminModalTable";
import { useNavigate } from "react-router";
import UploadMedia from "../modalComponents/UploadMedia";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";
import { updateJobData } from "../../redux-slices/jobsSlice";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { closeJobModal } from "../../redux-slices/modalSlice";
import { resetOrderData } from "../../redux-slices/editOrderDataSlice";
import { toast } from "react-toastify";

const MechanicModalDetails = ({
  jobModalOpen,
  closeModal,
  loading,
  setLoading,
  job,
  fetchJobData,
  id,
  fetchData,
  setOrderEdit,
}) => {
  const headerTitles = [
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Quantity",
    },
    {
      id: 4, //Admin Table also has From Column as ID 4
      title: "From",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { hasUpdatedData } = useSelector((state) => state.editOrderData);
  const { showJobModalActions } = useSelector((state) => state.modals);

  const [remarks, setRemarks] = useState("");
  const [media, setMedia] = useState([]);
  const [editOrderData, setEditOrderData] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    setEditOrderData(false);
  }, [job]);

  useEffect(() => {
    if (media.length > 0) {
      submitMedia();
    }
    // eslint-disable-next-line
  }, [media]);

  const submitMedia = () => {
    handleMediaSubmit(
      media,
      id,
      setMedia,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const submitRemarks = () => {
    handleRemarksSubmit(
      remarks,
      id,
      setRemarks,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const handleComplete = () => {
    if (!job?.vehicle?.mileage || !job?.vehicle?.chassis_number) {
      return toast.error("Odometer and Chassis Number are required to complete the job");
    }

    if (editOrderData) {
      saveOrderData();
    }

    if (remarks) {
      submitRemarks();
    }

    dispatch(
      completedByMechanic({
        id,
        onSuccess: () => {
          fetchData();
          closeModal();
        },
      })
    );
  };

  const handleViewLogs = () => {
    if (editOrderData) {
      saveOrderData();
    }
    if (remarks) {
      submitRemarks();
    }
    closeModal();
    navigate(`/${user?.roles?.[0]?.name}/logs/${id}`, {
      state: { job_id: id },
    });
  };

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  const saveOrderData = () => {
    setEditOrderData(false);
    if (hasUpdatedData) {
      dispatch(closeJobModal());
      dispatch(
        updateJobData({
          onSuccess: () => {
            fetchData();
          },
        })
      );
    }
  };

  const editOrderButtons = [
    {
      id: 0,
      title: "Save Changes",
      color: "#f3a366" //Orange
    },
    {
      id: 1,
      title: "Cancel Changes",
      color: "#fb7d7d" //Red
    }
  ]

  const handleEditOrderButtons = (item) => {
    switch(item.id){
      case 0:
        saveData();
        setLoading(true);
        setEditOrderData(false);
        break;
      case 1:
        setLoading(true);
        dispatch(resetOrderData());
        setEditOrderData(false);
        setOrderEdit(val => !val);
        break;
      default:
        break;
    }
  }

  const saveData = () => {
    if (editOrderData) {
      setEditOrderData(false);
      if (hasUpdatedData) {
        dispatch(updateJobData({
          onSuccess : () => fetchJobData(setLoading)
        }));
      }
    }
    if (remarks) {
      submitRemarks();
    }
  };

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-xl md:text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading ? "Service Order" : `Service Order ${job?.display_id}`}
            </Typography>
            <div className="flex justify-center items-center">
              <img
                src={editImg}
                alt="Edit data"
                className={`ml-5 h-6 cursor-pointer ${
                  editOrderData ? "hidden" : ""
                }`}
                onClick={() => setEditOrderData(true)}
              />
              <img
                src={PrintImg}
                alt="PrintImg"
                className="ml-5 cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <OrderData
                editOrderData={editOrderData}
                fetchJobData={fetchJobData}
                data={job}
              />

              <HeadingwithData
                heading={"Service/Repair Required"}
                data={job?.description}
                job_id={job?.id}
                editOrderData={editOrderData}
              />
              {job?.remarks.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks
                    remarks={job?.remarks}
                    fetchData={fetchJobData}
                  />
                </div>
              )}

              <div className={job?.remarks?.length === 0 ? "mt-5" : ""}>
                <AddRemarks
                  remarks={remarks}
                  setRemarks={setRemarks}
                  submitRemarks={submitRemarks}
                />
              </div>
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              {job?.parts_by_customer?.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Parts Available (User / Garage)
                  </Typography>

                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_customer}
                    partBy="customer"
                    tableUsedBy={"mechanic"}
                  />
                </>
              )}

              {job?.parts_by_garage.length > 0 && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Pick up Requests
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_garage}
                    partBy="garage"
                    tableUsedBy={"mechanic"}
                  />
                </>
              )}
              {job?.media_by_customer.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div>
                <ShowMedia
                  userRole={user?.roles?.[0]?.name}
                  media={job?.media_by_garage}
                  fetchJobData={fetchJobData}
                />
              </div>
              <div>
                <UploadMedia media={media} setMedia={setMedia} />
              </div>
            </div>
          </div>
        )}
        {uploadLoading && (
          <div>
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        { editOrderData ? (
          editOrderButtons.map((item) => (
            <Button
              key={item.id}
              className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base md:w-44`}
              style={{
                backgroundColor: item.color,
                textTransform: "none"
              }}
              onClick={() => handleEditOrderButtons(item)}
            >
              {item.title}
            </Button>
          ))
        ) : (
          <>
            <Button
            className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
            style={{
              textTransform: "none",
              background: theme.palette.orange.main,
            }}
            onClick={handleViewLogs}
            >
              View Logs
            </Button>
            <Button
              className="h-10 md:w-36 text-white mt-7 md:mr-5 rounded-lg text-base"
              style={{
                textTransform: "none",
                background: theme.palette.orange.main,
              }}
              onClick={handleComplete}
            >
              Send for Review
            </Button>
          </>
        ) }
        
      </DialogActions>
    </Dialog>
  );
};

export default MechanicModalDetails;
