import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchParts = createAsyncThunk(
  "parts/fetchParts",
  async ({ search = "" }, { rejectWithValue }) => {
    // Explicitly destructure search
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-parts-crud`,
        { params: { search } } // Use axios params for query string
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data || { message: error.message }
      );
    }
  }
);

export const createPart = createAsyncThunk(
  "parts/createPart",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-parts`,
        data
      );
      onSuccess();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updatePart = createAsyncThunk(
  "parts/updatePart",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/edit-parts/${data.id}`,
        data
      );
      onSuccess();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deletePart = createAsyncThunk(
  "parts/deletePart",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-parts-list/${id}`
      );
      onSuccess();
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const partsSlice = createSlice({
  name: "parts",
  initialState: {
    parts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchParts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchParts.fulfilled, (state, action) => {
        state.loading = false;
        state.parts = action.payload;
      })
      .addCase(fetchParts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createPart.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPart.fulfilled, (state, action) => {
        state.loading = false;
        state.parts.push(action.payload);
      })
      .addCase(createPart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePart.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePart.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.parts.findIndex(
          (part) => part.id === action.payload.id
        );
        if (index !== -1) {
          state.parts[index] = action.payload;
        }
      })
      .addCase(updatePart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deletePart.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePart.fulfilled, (state, action) => {
        state.loading = false;
        state.parts = state.parts.filter((part) => part.id !== action.payload);
      })
      .addCase(deletePart.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default partsSlice.reducer;
