import { Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { capitalizeWords, formatStatus } from "../../utils/helpers";
import editImg from "../../assets/images/edit.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  closeJobModal,
  openMechanicSelectModal,
  setReassignFalse,
} from "../../redux-slices/modalSlice";
import { updateOrderData } from "../../redux-slices/editOrderDataSlice";
import { updateJobData } from "../../redux-slices/jobsSlice";
import LicenseInput from "../commonComponents/LicenseInput";

export const OrderData = ({
  data,
  showUserData = false,
  editOrderData = false,
  remarks,
  submitRemarks,
  setEditOrderData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { hasUpdatedData } = useSelector((state) => state.editOrderData);

  const [name, setName] = useState(data?.customer?.user?.name || "");
  const [email, setEmail] = useState(data?.customer?.user?.email || "");
  const [phone, setPhone] = useState(data?.customer?.phone_num || "");
  const [invoiceNumber, setInvoiceNumber] = useState(
    data?.invoice_number || ""
  );
  const [licensePlate, setLicensePlate] = useState(
    data?.vehicle?.license_number || ""
  );
  const [makeModel, setMakeModel] = useState(
    data?.vehicle?.make_and_model || ""
  );
  const [year, setYear] = useState(data?.vehicle?.year || "");
  const [chassisNumber, setChassisNumber] = useState(
    data?.vehicle?.chassis_number || ""
  );
  const [mileage, setMileage] = useState(data?.vehicle?.mileage || "");
  const [mileageUnit, setMileageUnit] = useState(
    data?.vehicle?.mileage_unit || ""
  );

  const onChange = (index, key, setKey) => (e) => {
    let value = e.target.value;

    if (key === "chassis_number") {
      value = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
    }

    setKey(value); // Update the local state
    dispatch(updateOrderData({ job_id: data.id, index, key, value })); // Update global state
  };

  const updateLicensePlate = (license) => {
    license = license.trim();
    if (license.endsWith(" -")) {
      license = license.slice(0, -2);
    }

    onChange(
      "vehicle",
      "license_number",
      setLicensePlate
    )({ target: { value: license } });
  };

  return (
    <Grid container>
      {showUserData && (
        <>
          <Grid className="mb-2" item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Name
            </Typography>

            {editOrderData ? (
              <input
                type="text"
                value={name}
                onChange={onChange("user", "name", setName)}
                className="w-2/3 border-gray-200 border rounded-md p-1"
              />
            ) : (
              <Typography
                className="text-sm font-normal"
                style={{ color: theme.palette.blue.main }}
              >
                {name}
              </Typography>
            )}
          </Grid>

          <Grid className="mb-2" item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Email
            </Typography>
            {editOrderData ? (
              <input
                type="email"
                value={email}
                onChange={onChange("user", "email", setEmail)}
                className="w-2/3 border-gray-200 border rounded-md p-1"
              />
            ) : (
              <Typography
                className="text-sm font-normal"
                style={{ color: theme.palette.blue.main }}
              >
                {email}
              </Typography>
            )}
          </Grid>

          <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
            <Typography
              className="text-sm font-medium mb-1"
              style={{ color: theme.palette.orange.main }}
            >
              Phone Number
            </Typography>
            {editOrderData ? (
              <input
                type="tel"
                value={phone}
                onChange={onChange("customer", "phone_num", setPhone)}
                className="w-2/3 border-gray-200 border rounded-md p-1"
              />
            ) : (
              <Typography
                className="text-sm font-normal"
                style={{ color: theme.palette.blue.main }}
              >
                {phone}
              </Typography>
            )}
          </Grid>
        </>
      )}
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Status
        </Typography>
        <Typography
          className="text-sm font-normal"
          style={{ color: theme.palette.blue.main }}
        >
          <span>{formatStatus(data?.status)}</span>
        </Typography>
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Date & Time
        </Typography>
        <Typography
          className="text-sm font-normal"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.date_time || ""}
        </Typography>
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Duration in Garage
        </Typography>
        <Typography
          className="text-sm font-normal"
          style={{ color: theme.palette.blue.main }}
        >
          {data?.duration_in_garage || ""}
        </Typography>
      </Grid>
      {data?.completed_at && (
        <Grid className="my-2" item xs={12} sm={12} md={12} lg={6}>
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Finish Date
          </Typography>
          <Typography
            className="text-sm font-normal"
            style={{ color: theme.palette.blue.main }}
          >
            {data.completed_at}
          </Typography>
        </Grid>
      )}
      {data?.invoice_number && (
        <Grid className="my-2" item xs={12} sm={12} md={12} lg={6}>
          <Typography
            className="text-sm font-medium mb-1"
            style={{ color: theme.palette.orange.main }}
          >
            Invoice Number
          </Typography>
          {editOrderData ? (
            <input
              type="text"
              value={invoiceNumber}
              onChange={onChange("job", "invoice_number", setInvoiceNumber)}
              className="w-2/3 border-gray-200 border rounded-md p-1"
            />
          ) : (
            <Typography
              className="text-sm font-normal"
              style={{ color: theme.palette.blue.main }}
            >
              {invoiceNumber}
            </Typography>
          )}
        </Grid>
      )}
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          License Plate
        </Typography>
        {editOrderData ? (
          <LicenseInput
            licensePlate={licensePlate}
            setLicense={updateLicensePlate}
          />
        ) : (
          <Typography
            className="text-sm font-normal"
            style={{ color: theme.palette.blue.main }}
          >
            {licensePlate}
          </Typography>
        )}
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Make & Model
        </Typography>
        {editOrderData ? (
          <input
            type="text"
            value={makeModel}
            onChange={onChange("vehicle", "make_and_model", setMakeModel)}
            className="w-2/3 border-gray-200 border rounded-md p-1"
          />
        ) : (
          <Typography
            className="text-sm font-normal"
            style={{ color: theme.palette.blue.main }}
          >
            {capitalizeWords(makeModel)}
          </Typography>
        )}
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Year
        </Typography>
        {editOrderData ? (
          <input
            type="number"
            value={year}
            onChange={onChange("vehicle", "year", setYear)}
            className="w-2/3 border-gray-200 border rounded-md p-1"
          />
        ) : (
          <Typography
            className="text-sm font-normal"
            style={{ color: theme.palette.blue.main }}
          >
            {year}
          </Typography>
        )}
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Vin/Chassis #
        </Typography>
        {editOrderData ? (
          <input
            type="text"
            value={chassisNumber}
            onChange={onChange("vehicle", "chassis_number", setChassisNumber)}
            className="w-2/3 border-gray-200 border rounded-md p-1"
          />
        ) : (
          <Typography
            className="text-sm font-normal"
            style={{ color: theme.palette.blue.main }}
          >
            {chassisNumber ?? "-"}
          </Typography>
        )}
      </Grid>
      <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
        <Typography
          className="text-sm font-medium mb-1"
          style={{ color: theme.palette.orange.main }}
        >
          Odometer
        </Typography>
        <div
          className="text-sm font-normal"
          style={{ color: theme.palette.blue.main }}
        >
          {editOrderData ? (
            <div className="flex w-2/3 border-gray-200 border rounded-md p-1">
              <input
                type="number"
                value={mileage}
                onChange={onChange("vehicle", "mileage", setMileage)}
                className="w-full"
              />
              <select
                value={mileageUnit}
                onChange={onChange("vehicle", "mileage_unit", setMileageUnit)}
              >
                <option value="km">km</option>
                <option value="mi">mi</option>
              </select>
            </div>
          ) : mileage ? (
            mileage + " " + mileageUnit
          ) : (
            "-"
          )}
        </div>
      </Grid>
      {(user.roles[0].name === "supervisor" ||
        user.roles[0].name === "admin") &&
        (data?.status === "in-progress" || data?.status === "reassigned") && (
          <Grid className="my-2" item xs={12} sm={6} md={6} lg={6}>
            <div className="w-3/5 flex justify-between items-center">
              <Typography
                className="text-sm font-medium mb-1"
                style={{ color: theme.palette.orange.main }}
              >
                Mechanic
              </Typography>
            </div>
            <div
              className="text-sm font-normal"
              style={{ color: theme.palette.blue.main }}
            >
              {data?.mechanic || "-"}
            </div>
          </Grid>
        )}
    </Grid>
  );
};
