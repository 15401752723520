import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import settingsWhite from "../../assets/images/settingsWhite.svg";
import settings from "../../assets/images/settingOrange.svg";
import logout_white from "../../assets/images/logout_white.svg";
import logout from "../../assets/images/logout.svg";
import carRect from "../../assets/images/CarRect.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux-slices/authSlice";
import { useNavigate } from "react-router";
import { Tooltip, Typography } from "@mui/material";
import { NotificationComponent } from "./NotificationComponent";

export const MainHeader = ({ setTab, defaultTab }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(
      logoutUser({
        onSuccess: () => navigate("/login"),
      })
    );
  };

  const handleSettings = () => {
    navigate("/admin/settings");
  };

  const handleLogoClick = () => {
    if (setTab) {
      setTab(defaultTab);
    } else {
      navigate(`/${user.roles[0].name}`);
    }
  };

  return (
    <div className="flex flex-row justify-between h-24 sm:h-32">
      <div className="md:pl-5">
        <img
          onClick={handleLogoClick}
          src={logo}
          alt="logo"
          className="cursor-pointer h-full"
        />
      </div>
      <div className="relative justify-end pr-20 items-center w-full">
        <img
          src={carRect}
          alt=""
          className="absolute w-full h-full left-0 top-0 hidden sm:block"
        />
        <div className="absolute w-full h-full flex justify-end items-center z-10 md:pr-10 pr-7">
          <div className="mr-12">
            <Typography className="text-[#F3A366] sm:text-white text-base mt-0.5">
              {user?.name}
            </Typography>
          </div>
          <div
            className={`flex h-6 items-center justify-between ${
              user.roles[0].name === "admin" ? "w-32 sm:w-44" : "w-24"
            }`}
          >
            {windowWidth >= 640 && (
              <>
                {user?.roles[0].name !== "driver" && <NotificationComponent />}
                {user?.roles[0].name === "admin" && (
                  <Tooltip title="Settings">
                    <img
                      src={settingsWhite}
                      className="cursor-pointer"
                      onClick={handleSettings}
                      alt="Settings"
                    />
                  </Tooltip>
                )}
                <Tooltip title="Logout">
                  <img
                    src={logout_white}
                    className="cursor-pointer"
                    onClick={handleLogout}
                    alt="logout"
                  />
                </Tooltip>
              </>
            )}
            {windowWidth < 640 && (
              <>
                {user?.roles[0].name !== "driver" && <NotificationComponent />}
                {user?.roles[0].name === "admin" && (
                  <img
                    src={settings}
                    className="cursor-pointer"
                    onClick={handleSettings}
                    alt="Settings"
                  />
                )}
                <img
                  src={logout}
                  className="cursor-pointer"
                  onClick={handleLogout}
                  alt="logout"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
