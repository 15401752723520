import * as React from "react";
import { useEffect, useState } from "react";
import { getJob } from "../../redux-slices/jobsSlice";
import { useDispatch, useSelector } from "react-redux";
import { closeJobModal } from "../../redux-slices/modalSlice";
import ReceptionistModalDetails from "../receptionists/ReceptionistModalDetails";
import SupervisorModalDetails from "../supervisor/SupervisorModalDetails";
import MechanicModalDetails from "../mechanicFlow/MechanicModalDetails";
import AdminModalDetails from "../admin/AdminModalDetails";

export function JobDetailModal({ tab, fetchData }) {
  const dispatch = useDispatch();

  const { job } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);
  const { jobModalOpen, modalJobId } = useSelector((state) => state.modals);

  const [loading, setLoading] = useState(false);
  const [orderEdit, setOrderEdit] = useState(false);

  const fetchJobData = (setLoading) => {
    dispatch(getJob(modalJobId)).then(() => {
      if (setLoading) setLoading(false);
    });
  };

  const closeModal = () => {
    dispatch(closeJobModal());
  };

  useEffect(() => {
    if (modalJobId && jobModalOpen) {
      setLoading(true);
      fetchJobData(setLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalJobId, jobModalOpen, orderEdit]);

  return (
    <>
      {user?.roles[0]?.name === "receptionist" && (
        <ReceptionistModalDetails
          jobModalOpen={jobModalOpen}
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          job={job}
          tab={tab}
          fetchJobData={fetchJobData}
          id={modalJobId}
          fetchData={fetchData}
          setOrderEdit={setOrderEdit}
        />
      )}
      {user?.roles[0]?.name === "supervisor" && (
        <SupervisorModalDetails
          jobModalOpen={jobModalOpen}
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          job={job}
          tab={tab}
          fetchJobData={fetchJobData}
          id={modalJobId}
          fetchData={fetchData}
          setOrderEdit={setOrderEdit}
        />
      )}
      {user?.roles[0]?.name === "mechanic" && (
        <MechanicModalDetails
          jobModalOpen={jobModalOpen}
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          job={job}
          fetchJobData={fetchJobData}
          id={modalJobId}
          fetchData={fetchData}
          setOrderEdit={setOrderEdit}
        />
      )}
      {user?.roles[0]?.name === "admin" && (
        <AdminModalDetails
          jobModalOpen={jobModalOpen}
          closeModal={closeModal}
          loading={loading}
          setLoading={setLoading}
          job={job}
          fetchJobData={fetchJobData}
          fetchData={fetchData}
          id={modalJobId}
          setOrderEdit={setOrderEdit}
        />
      )}
    </>
  );
}
