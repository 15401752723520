import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { processFileDownload } from "../utils/helpers";

export const generateInvoice = createAsyncThunk(
  "invoiceSlice/generateInvoice",
  async ({data, onSuccess}, { rejectWithValue }) => {
    const loadingToast = toast.loading("Generating invoice...");
    try {
      console.log("Calling generateInvoice with data:", data);
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/generate-invoice`,
        data,
        { responseType: "blob" }
      );
      toast.dismiss(loadingToast);

      const contentType = response.headers["content-type"];
      if (contentType && contentType.includes("application/pdf")) {
        const contentDisposition = response.headers["content-disposition"];
        let filename = "Carfix Invoice.pdf";
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) filename = match[1];
        }

        onSuccess();
        return { file:response.data, filename };
      } else {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              toast.error("Failed to generate invoice: " + errorData.message);
              reject(rejectWithValue(errorData));
            } catch (parseError) {
              toast.error("Unknown error occurred!");
              reject(rejectWithValue({ message: "Unknown error occurred" }));
            }
          };
          reader.readAsText(response.data);
        });
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error(
        "An Error Occurred in Invoice Generation: " +
          (error.message || "Unknown error")
      );
      console.error("Error generating invoice:", error);
      
      if (error.response && error.response.data) {
        const errorData = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(JSON.parse(reader.result));
          reader.onerror = () => resolve({ message: "Error reading response" });
          reader.readAsText(error.response.data);
        });
        console.log("Error response content:", errorData);
        return rejectWithValue(errorData);
      }
      return rejectWithValue({ message: error.message });
    }
  }
);

export const fetchServices = createAsyncThunk(
  "invoiceSlice/fetchServices",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-services`
      );
      return response.data;
    } catch (error) {
      toast.error(
        "Failed to fetch services: " + (error.message || "Unknown error")
      );
      return rejectWithValue(
        error?.response?.data || { message: error.message }
      );
    }
  }
);

export const fetchParts = createAsyncThunk(
  "invoiceSlice/fetchParts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-parts`
      );
      return response.data;
    } catch (error) {
      toast.error(
        "Failed to fetch parts: " + (error.message || "Unknown error")
      );
      return rejectWithValue(
        error?.response?.data || { message: error.message }
      );
    }
  }
);

export const deleteService = createAsyncThunk(
  "invoiceSlice/deleteService",
  async (id, { rejectWithValue }) => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-service/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);
export const editInvoice = createAsyncThunk(
  "invoiceSlice/editInvoice",
  async ({ id, data, total, onSuccess }, { rejectWithValue }) => {
    const loadingToast = toast.loading("Updating invoice...");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/edit-invoice/${id}`,
        data,
        { responseType: "blob" } // Config object
      );
      toast.dismiss(loadingToast);

      const contentType = response.headers["content-type"];

      if (contentType && contentType.includes("application/pdf")) {
        const contentDisposition = response.headers["content-disposition"];
        let filename = "Carfix Invoice.pdf";

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1]; // e.g., invoice-any-2025-03-10.pdf
          }
        }

        toast.success("Invoice updated successfully!");
        if (onSuccess) onSuccess();
        return { file:response.data, filename };
      } else {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              toast.error("Failed to update invoice!");
              reject(rejectWithValue(errorData));
            } catch (parseError) {
              toast.error("Unknown error occurred!");
              reject(rejectWithValue({ message: "Unknown error occurred" }));
            }
          };
          reader.readAsText(response.data);
        });
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      toast.error("Failed to update invoice!");
      console.error("Error updating invoice:", error);

      if (error.response && error.response.data) {
        const reader = new FileReader();
        reader.onload = () =>
          console.log("Error response content:", reader.result);
        reader.readAsText(error.response.data);
      }

      return rejectWithValue(
        error?.response?.data || { message: error.message }
      );
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  "invoiceSlice/downloadInvoice",
  async ({ invoice_id, onSuccess }, { rejectWithValue }) => {
    const loadingToast = toast.loading("Downloading invoice...");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/download-invoice/${invoice_id}`,
        {
          responseType: "blob",
        }
      );
      toast.dismiss(loadingToast);
      const contentType = response.headers.get("content-type");
      if (contentType.includes("application/pdf")) {
        const contentDisposition = response.headers.get("content-disposition");
        let filename = "Carfix Invoice.pdf";

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }

        onSuccess();
        return { file: response.data, filename };
      } else {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              reject(rejectWithValue(errorData));
            } catch (parseError) {
              reject(rejectWithValue({ message: "Unknown error occurred" }));
            }
          };
          reader.readAsText(response.data);
        });
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const invoiceSlice = createSlice({
  name: "generalPickupOrder",
  initialState: {
    services:[],
    parts:[],
    status:"idle",
    error:null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateInvoice.fulfilled, (state, action) => {
        const { file, filename } = action.payload;
        processFileDownload(file, "download", filename);
        toast.success("Invoice generated successfully");
      })
      .addCase(generateInvoice.rejected, (state, action) => {
        toast.error(action.payload?.message || "Failed to generate invoice");
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        const { id } = action.payload;
        state.services = state.services.filter((service) => service.id !== id);
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.error = action.payload?.message || "Failed to delete service";
      })
      .addCase(editInvoice.fulfilled, (state, action) => {
        const { file, filename } = action.payload;
        processFileDownload(file, "download", filename);
      })
      .addCase(editInvoice.rejected, (state, action) => {
        toast.error(action.payload?.message || "Failed to edit invoice");
      })
      .addCase(downloadInvoice.fulfilled, (state, action) => {
        const { file, filename } = action.payload;
        processFileDownload(file, "download", filename);
        toast.success("Invoice downloaded successfully");
      })
      .addCase(downloadInvoice.rejected, (state, action) => {
        toast.error(
          action.payload?.message || "No Invoice available for this order!"
        );
      })
      .addCase(fetchServices.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch services";
      })
      // Fetch Parts
      .addCase(fetchParts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchParts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.parts = action.payload;
      })
      .addCase(fetchParts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload?.message || "Failed to fetch parts";
      });
  },
});

//eslint-disable-next-line
export const {} = invoiceSlice.actions;
export default invoiceSlice.reducer;
