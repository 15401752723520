import React, { useEffect, useRef, useState } from "react";
import { ClientLayout } from "../layouts/ClientLayout";
import {
  Button,
  Dialog,
  Grid,
  InputAdornment,
  Select,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import backImg from "../../assets/images/back.svg";
import addWhiteImg from "../../assets/images/addWhite.svg";
import addBlueImg from "../../assets/images/addBlue.svg";
import closeImg from "../../assets/images/close.svg";
import deleteImg from "../../assets/images/delete.svg";
import { useForm } from "react-hook-form";
import CarfixInput from "../../components/commonComponents/CarfixInput";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createJob } from "../../redux-slices/jobsSlice";
import UploadedMediaGallery from "../../components/modalComponents/UploadedMediaGallery";
import { toast } from "react-toastify";
import { getUserAndVehicle } from "../../redux-slices/usersSlice";
import dayjs from "dayjs";
import LicenseInput from "../../components/commonComponents/LicenseInput";
import { getVehicleNames } from "../../redux-slices/vehicleSlice";
import { formatNumericInputWithComma } from "../../utils/helpers";

export const ServiceForm = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [license, setLicense] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [time, setTime] = useState(
    new Date().toLocaleTimeString("en-US", { hour12: false }).slice(0, 5)
  );
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mileage, setMileage] = useState("");
  const [mileageUnit, setMileageUnit] = useState("km");
  const [makeAndModel, setMakeAndModel] = useState("");
  const [chassisNumber, setChassisNumber] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [parts, setParts] = useState([]);
  const [media, setMedia] = useState([]);
  const mediaRef = useRef();
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [openDontAgree, setOpenDontAgree] = useState(false);
  const [dataToSend, setDataToSend] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseDialog = () => {
    setShowDisclaimer(false);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      license,
      phone_num: phoneNum,
      email,
      name,
      make_and_model: makeAndModel,
      chassis_number: chassisNumber,
      year,
      mileage,
      mileage_unit: mileageUnit,
    },
  });

  const insertRow = () => {
    setParts([...parts, { quantity: "", part: "" }]);
  };

  const deleteRow = (index) => {
    const newParts = [...parts];
    newParts.splice(index, 1);
    setParts(newParts);
  };

  const handleChange = (index, field, value) => {
    const newParts = [...parts];
    newParts[index][field] = value;
    setParts(newParts);
  };

  const onSubmit = (data) => {
    if (media) data.media = media;

    for (let i = 0; i < parts.length; i++) {
      let part = parts[i];
      if (!part.part || !part.quantity)
        return toast.error("Your parts have missing fields");
    }

    if (!license) return toast.error("License is empty or invalid");

    data.parts = parts;

    const formattedData = {
      ...data,
      date: dayjs().format("YYYY-MM-DD"),
      time: dayjs().format("HH:mm"),
      mileage: data.mileage.includes(",")
        ? data.mileage.replace(/,/g, "")
        : data.mileage,
    };

    setShowDisclaimer(true);
    setDataToSend(formattedData);
  };

  const handleDontAgree = () => {
    setOpenDontAgree(true);
    setShowDisclaimer(false);
  };

  const handleCloseDontAgree = () => {
    setShowDisclaimer(true);
    setOpenDontAgree(false);
  };

  const proceedSubmit = () => {
    dispatch(
      createJob({
        data: dataToSend,
        onSuccess: () => navigate("/"),
      })
    ).then(() => handleCloseDialog());
  };

  useEffect(() => {
    const handle = setTimeout(() => {
      if (license && license.length > 0) {
        dispatch(getUserAndVehicle({ license, phnNumber: "" })).then((res) => {
          const data = res.payload;
          if (data) {
            setEmail(data.email);
            setName(data.name);
            setYear(data.year);
            setPhoneNum(data.phone_num);
            setMakeAndModel(data.make_and_model);
            setChassisNumber(data.chassis_number);
            handleMileageChange({
              target: { value: data.mileage },
            });
            setMileageUnit(data.mileage_unit);
          }
        });
      }
    }, 1000);

    return () => {
      clearTimeout(handle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [license]);

  const handleMileageChange = (e) => {
    const formattedValue = formatNumericInputWithComma(e.target.value);
    setMileage(formattedValue);
  };

  const fetchSuggestions = async (str) => {
    try {
      const response = await dispatch(
        getVehicleNames({ inputString: str })
      ).unwrap();
      setSuggestions(response);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleMakeModelChange = (e) => {
    const lowerCaseValue = e.target.value.toLowerCase().replace(/[^a-z ]/g, "");
    setMakeAndModel(lowerCaseValue);

    if (lowerCaseValue.length > 0) {
      fetchSuggestions(lowerCaseValue);
      console.log("Suggestions:", suggestions);
    } else {
      setSuggestions([]);
      console.log("No suggestions", suggestions);
    }
  };

  return (
    (document.title = "Carfix Curacao | New Order"),
    (
      <ClientLayout>
        <div className="relative bg-calc">
          <div className="absolute z-10 w-full bg-backImg scrollbar-hidden sm:px-16 lg:sm:px-0">
            <div className="flex px-4">
              <img
                className="cursor-pointer"
                src={backImg}
                alt=""
                onClick={() => navigate(-1)}
              />
              <Typography
                className="font-bold ml-4 sm:text-3xl text-2xl"
                style={{ color: theme.palette.text.darkBlue }}
              >
                Service Order Form
              </Typography>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4 sm:w-6/7 md:w-11/12">
                <Grid container columnSpacing={3} rowSpacing={1}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Date
                    </Typography>
                    <CarfixInput
                      {...register("date", { required: "Date is required" })}
                      className="w-full"
                      type="date"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)}
                      error={Boolean(errors?.date)}
                      helperText={errors?.date?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Time
                    </Typography>
                    <CarfixInput
                      {...register("time", { required: "Time is required" })}
                      className="w-full"
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      error={Boolean(errors?.time)}
                      helperText={errors?.time?.message}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      License Plate#
                    </Typography>
                    <LicenseInput
                      register={register}
                      errors={errors}
                      setLicense={setLicense}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Make & Model
                    </Typography>
                    <div className="relative">
                      <CarfixInput
                        {...register("make_and_model", {
                          required: "Make & Model is required",
                        })}
                        value={makeAndModel}
                        onChange={handleMakeModelChange}
                        className="w-full"
                        placeholder="Enter Make & Model"
                        autoComplete="off"
                      />
                      {Boolean(errors?.make_and_model) && (
                        <p className="text-red-500 text-sm">
                          {errors?.make_and_model?.message}
                        </p>
                      )}
                      {suggestions.length > 0 && (
                        <ul
                          className="absolute bg-white border border-gray-300 w-full mt-1 max-h-40 overflow-y-auto 
                        z-10 rounded-xl"
                        >
                          {suggestions.map((suggestion, index) => (
                            <li
                              key={index}
                              onClick={() => {
                                setMakeAndModel(suggestion);
                                setSuggestions([]);
                              }}
                              className="p-2 cursor-pointer hover:bg-orange-100"
                            >
                              {suggestion}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Name
                    </Typography>
                    <CarfixInput
                      {...register("name", { required: "Name is required" })}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full"
                      placeholder="Enter Name"
                      error={Boolean(errors?.name)}
                      helperText={errors?.name?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Phone Number
                    </Typography>
                    <CarfixInput
                      {...register("phone_num", {
                        required: "Phone Number is required",
                      })}
                      value={phoneNum}
                      onChange={(e) => setPhoneNum(e.target.value)}
                      className="w-full"
                      type="tel"
                      placeholder="Enter Phone Number"
                      error={Boolean(errors?.phone_num)}
                      helperText={errors?.phone_num?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Email
                    </Typography>
                    <CarfixInput
                      {...register("email", { required: "Email is required" })}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full"
                      type="email"
                      placeholder="Enter Email"
                      error={Boolean(errors?.email)}
                      helperText={errors?.email?.message}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Model Year
                    </Typography>
                    <CarfixInput
                      {...register("year", {
                        required: "Year is Required",
                      })}
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      className="w-full"
                      type="number"
                      placeholder="Enter Year"
                      error={Boolean(errors?.year)}
                      helperText={errors?.year?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Vin/Chassis #
                    </Typography>
                    <CarfixInput
                      {...register("chassis_number")}
                      value={chassisNumber}
                      onChange={(e) => {
                        const uppercaseValue = e.target.value
                          .toUpperCase()
                          .replace(/[^A-Z0-9]/g, ""); // Keep only uppercase letters and digits
                        setChassisNumber(uppercaseValue);
                      }}
                      className="w-full"
                      type="text"
                      placeholder="Enter Vin/Chassis"
                      error={Boolean(errors?.chassis_number)}
                      helperText={errors?.chassis_number?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Odometer
                    </Typography>
                    <CarfixInput
                      {...register("mileage")}
                      value={mileage}
                      onChange={handleMileageChange}
                      className="w-full"
                      type="text"
                      placeholder="Odometer"
                      error={Boolean(errors?.mileage)}
                      helperText={errors?.mileage?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Select
                              {...register("mileage_unit")}
                              sx={{
                                "& fieldset": { border: "none" },
                                borderRadius: 0,
                              }}
                              native
                              className="text-sm"
                              style={{ width: 70 }}
                              value={mileageUnit}
                              onChange={(e) => setMileageUnit(e.target.value)}
                            >
                              <option value="km">km</option>
                              <option value="mi">mi</option>
                            </Select>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography
                      className="mb-2"
                      style={{
                        fontSize: 14,
                        color: theme.palette.text.darkBlue,
                      }}
                    >
                      Service Description
                    </Typography>
                    <CarfixInput
                      {...register("description", {
                        required: "Description is Required",
                      })}
                      className="text-sm w-full"
                      multiline
                      rows={4}
                      placeholder="Enter Service Description"
                      error={Boolean(errors?.description)}
                      helperText={errors?.description?.message}
                    />

                    <TableContainer
                      sx={{
                        borderRadius: 2,
                      }}
                      className="mt-4"
                      component={Paper}
                    >
                      <Table sx={{ maxWidth: "100%" }}>
                        <TableHead
                          style={{
                            backgroundColor:
                              screenWidth > theme.breakpoints.values.sm
                                ? theme.palette.orange.main
                                : theme.palette.blue.main,
                          }}
                        >
                          <TableRow>
                            <TableCell className="text-white">
                              Product/Part
                            </TableCell>
                            <TableCell className="text-white w-[30%]">
                              Quantity
                            </TableCell>
                            <TableCell className="text-center"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {parts.map((part, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell className="px-4">
                                <input
                                  className="w-full text-left font-normal text-sm"
                                  type="text"
                                  value={part.part}
                                  onChange={(e) =>
                                    handleChange(index, "part", e.target.value)
                                  }
                                  placeholder="Part Name"
                                />
                              </TableCell>
                              <TableCell className="px-4">
                                <input
                                  className="w-full text-left font-normal text-sm"
                                  type="number"
                                  value={part.quantity}
                                  onChange={(e) => {
                                    if (e.target.value < 0) return;

                                    handleChange(
                                      index,
                                      "quantity",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Quantity"
                                />
                              </TableCell>
                              <TableCell>
                                <div className="flex justify-center">
                                  <img
                                    className="cursor-pointer min-w-4"
                                    src={deleteImg}
                                    alt="Delete"
                                    onClick={() => deleteRow(index)}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                          <TableRow sx={{ height: 50 }}>
                            <TableCell colSpan={3}>
                              <div className="flex justify-center">
                                <img
                                  className="cursor-pointer"
                                  onClick={insertRow}
                                  src={
                                    screenWidth > theme.breakpoints.values.sm
                                      ? addWhiteImg
                                      : addBlueImg
                                  }
                                  alt="Add"
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>

                <div className="mt-8">
                  <UploadedMediaGallery
                    media={media}
                    setMedia={setMedia}
                    mediaRef={mediaRef}
                    width={screenWidth}
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-32 h-10 mt-10 mb-10 client-btn ml-2"
                  >
                    <Typography className="text-white">Submit</Typography>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={showDisclaimer}
          onClose={handleCloseDialog}
          PaperProps={{
            className: "p-5 rounded-3xl",
            sx: {
              borderWidth: 5,
              borderColor: "orange.main",
              color: "blue.main",
            },
          }}
        >
          <div className="flex flex-col items-center">
            <div className="flex justify-between items-center w-full mb-3 px-3">
              <div />
              <Typography className="font-bold text-xl">Disclaimer</Typography>
              <img
                src={closeImg}
                alt="Close"
                className="cursor-pointer"
                style={{ justifySelf: "flex-end" }}
                onClick={handleCloseDialog}
              />
            </div>

            <Typography variant="body2" className="text-center mb-5 text-sm">
              Please be aware that a diagnostic fee of fl. 45,- can be applied
              to your total bill. Partial or complete payment can be required in
              advance before work is done.
              <br />
              <br />
              Payment MUST be cancelled in full before vehicle leaves the
              garage. Vehicles left more than 2 days after completion of job can
              be charged aNAFL. 15,- storage fee per day or the maximum amount
              allowed by law. We are not responsible for your vehicle during
              this period. Vehicles left for more than 30 days can be auctioned
              off or sold for the open balance.
            </Typography>

            <div className="flex justify-between w-2/3">
              <Button
                color="orange"
                variant="outlined"
                className="rounded-full font-bold w-40 border-2"
                onClick={handleDontAgree}
              >
                I Don't Agree
              </Button>
              <Button
                color="orange"
                variant="contained"
                className="rounded-full text-white font-bold w-40"
                onClick={proceedSubmit}
              >
                Accept & Submit
              </Button>
            </div>
          </div>
        </Dialog>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDontAgree}
          onClose={() => setOpenDontAgree(false)}
          PaperProps={{
            className: "p-5 rounded-3xl",
            sx: {
              borderWidth: 5,
              borderColor: "orange.main",
              color: "blue.main",
              width: "30%",
            },
          }}
        >
          <div className="flex flex-col items-center">
            <div className="flex justify-between items-center w-full mb-3 px-3">
              <div />
              <img
                src={closeImg}
                alt="Close"
                className="cursor-pointer"
                style={{ justifySelf: "flex-end" }}
                onClick={() => setOpenDontAgree(false)}
              />
            </div>
            <Typography variant="body2" className="text-center mb-5 text-sm">
              Are you sure you want to cancel this registration? Your vehicle
              <Typography
                variant="body2"
                className="text-center mb-5 text-sm font-semibold"
              >
                will not be registered!
              </Typography>
            </Typography>

            <div className="flex justify-between w-2/3">
              <Button
                color="orange"
                variant="outlined"
                className="rounded-full font-bold w-40 border-2"
                onClick={() => setOpenDontAgree(false)}
              >
                Yes
              </Button>
              <Button
                color="orange"
                variant="contained"
                className="rounded-full text-white font-bold w-40"
                onClick={handleCloseDontAgree}
              >
                No
              </Button>
            </div>
          </div>
        </Dialog>
      </ClientLayout>
    )
  );
};
