import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotifications,
  increaseLimit,
  markAsRead,
  resetLimit,
} from "../redux-slices/notificationSlice";

const useNotifications = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector((state) => state.notifications);

  const fetchNotifications = () => {
    dispatch(getNotifications());
  };

  const markAllAsRead = () => {
    dispatch(markAsRead());
  };

  const increaseNotificationLimit = () => {
    dispatch(increaseLimit());
    fetchNotifications();
  };

  const resetNotificationLimit = () => {
    dispatch(resetLimit());
    fetchNotifications();
  };

  const startPolling = () => {
    const existingIntervalId = localStorage.getItem("notificationIntervalId");

    if (existingIntervalId) {
      clearInterval(Number(existingIntervalId));
      localStorage.removeItem("notificationIntervalId");
    }

    const intervalId = setInterval(() => {
      fetchNotifications();
    }, 30000);

    localStorage.setItem("notificationIntervalId", intervalId);
  };

  const stopPolling = () => {
    const existingIntervalId = localStorage.getItem("notificationIntervalId");

    if (existingIntervalId) {
      clearInterval(Number(existingIntervalId));
      localStorage.removeItem("notificationIntervalId");
    }
  };

  useEffect(() => {
    // Fetch notifications initially and start polling
    if (!notifications?.length) fetchNotifications();
    startPolling();

    // Cleanup on unmount
    return () => {
      stopPolling();
    };
    //eslint-disable-next-line
  }, []);

  return {
    notifications,
    fetchNotifications,
    increaseNotificationLimit,
    resetNotificationLimit,
    markAllAsRead,
  };
};

export default useNotifications;
