import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getUserAndVehicle = createAsyncThunk(
  "jobsSlice/getUserAndVehicle",
  async ({ license, phnNumber }, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-user-and-vehicle`,
        { params: { license, phnNumber } }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getGarageStaff = createAsyncThunk(
  "jobsSlice/getGarageStaff",
  async ({ search = "" }, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${
          process.env.REACT_APP_API_BASE_URL
        }/get-garage-staff?search=${encodeURIComponent(search)}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getMechanics = createAsyncThunk(
  "jobsSlice/getMechanics",
  async (_, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-mechanics`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addUser = createAsyncThunk(
  "jobsSlice/addUser",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-user`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const editUser = createAsyncThunk(
  "jobsSlice/editUser",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/edit-user`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "jobsSlice/deleteUser",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-user`,
        { params: { id } }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAndVehicle.fulfilled, (state, action) => {
      toast.success(
        action.payload?.message ?? "User and vehicle fetched successfully"
      );
    });

    builder.addCase(getGarageStaff.rejected, (state, action) => {
      toast.error(
        action.payload?.message ?? "Failed to fetch user and vehicle"
      );
    });

    builder.addCase(getMechanics.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to fetch mechanics");
    });

    builder.addCase(addUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "User added successfully");
    });
    builder.addCase(addUser.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to add user");
    });

    builder.addCase(editUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "User updated successfully");
    });
    builder.addCase(editUser.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to update user");
    });

    builder.addCase(deleteUser.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "User deleted successfully");
    });
    builder.addCase(deleteUser.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Failed to delete user");
    });
  },
});

export default usersSlice.reducer;
