import { configureStore } from "@reduxjs/toolkit";
import jobs from "./redux-slices/jobsSlice";
import auth from "./redux-slices/authSlice";
import modals from "./redux-slices/modalSlice";
import users from "./redux-slices/usersSlice";
import generalPickup from "./redux-slices/generalPickupOrderSlice";
import editOrderData from "./redux-slices/editOrderDataSlice";
import notifications from "./redux-slices/notificationSlice";
import vehicleSlice from "./redux-slices/vehicleSlice";
import invoice from "./redux-slices/invoiceSlice";
import services from "./redux-slices/servicesSlice";
import parts from "./redux-slices/partsSlice";

export const store = configureStore({
  reducer: {
    jobs,
    auth,
    modals,
    users,
    generalPickup,
    editOrderData,
    notifications,
    vehicleSlice,
    invoice,
    services,
    parts,
  },
});
