import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";


export const fetchServices = createAsyncThunk(
  "servicesSlice/fetchServices", // Fix naming mismatch
  async ({ search = "" }, { rejectWithValue }) => {
    // Explicitly destructure search
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/fetch-services-crud`,
        { params: { search } } // Use axios params for cleaner query string
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error?.response?.data || { message: error.message }
      );
    }
  }
);

export const deleteService = createAsyncThunk(
  "services/deleteService",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-services/${id}`
      ); 
      onSuccess();
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createService = createAsyncThunk(
  "services/createService",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-services`,
        data
      );
      onSuccess();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateService = createAsyncThunk(
  "services/updateService",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/edit-services/${data.id}`,
        data
      );
      onSuccess();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const servicesSlice = createSlice({
  name: "services",
  initialState: {
    services: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchServices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.loading = false;
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
         toast.error(
           action.payload?.message ?? "Failed to fetch user and vehicle"
         );
      })
      .addCase(deleteService.pending, (state) => {
        state.loading = true;
        
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.loading = false;
        state.services = state.services.filter(
          (service) => service.id !== action.payload
        );
        toast.error("Service Deleted Successfully");
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Error in Deleting Service");
      })
      .addCase(createService.pending, (state) => {
        state.loading = true;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.loading = false;
        state.services.push(action.payload);
        toast.success("Service Added")
      })
      .addCase(createService.rejected, (state, action) => {
        state.loading = false;
        toast.error("Error adding Service")
      })
      .addCase(updateService.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.services.findIndex(
          (service) => service.id === action.payload.id
        );
        if (index !== -1) {
          state.services[index] = action.payload;
        }
        toast.success("Service updated Successfully");
      })
      .addCase(updateService.rejected, (state, action) => {
        state.loading = false;
        toast.error("Error Updating Service")
      });
  },
});

export default servicesSlice.reducer;
