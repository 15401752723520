import { addMedia, addRemarks } from "../redux-slices/jobsSlice";

export const truncateText = (text, maxWords) => {
  if (!text) return "";
  const words = text.split(" ");
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(" ") + "...";
  }
  return text;
};

export const getStatusColor = (status) => {
  switch (status) {
    case "completed":
      return "#2F80ED";
    case "invoiced":
      return "#E2B93B";
    case "in-progress":
      return "#27AE60";
    case "awaiting-parts":
      return "#E2B93B";
    case "awaiting-review":
      return "#E2B93B";
    case "to-be-assigned":
      return "#EB5757";
    case "to-be-invoiced":
      return "#E2B93B";
    case "assigned-to-supervisor":
      return "#E2B93B";
    case "reassigned":
      return "#27AE60";
    case "new-order":
      return "#2F80ED";
    case "awaiting-mechanic":
      return "#E2B93B";
    case "pending":
      return "#E2B93B";
    case "cancelled":
      return "#EB5757";
    default:
      return "gray";
  }
};

export const formatStatus = (status) => {
  if (!status) return "";
  return status
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const dropdownStatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Ready for Pickup", value: "ready-for-pickup" },
  { label: "Out for Pick up", value: "out-for-pick-up" },
  { label: "On Hand", value: "on-hand" },
  { label: "Delivered", value: "delivered" },
];

export const dropdownFromOptions = [
  { label: "User", value: "user" },
  { label: "Garage", value: "garage" },
];

export const handleMediaSubmit = (
  media,
  id,
  setMedia,
  fetchJobData,
  setLoading,
  setUploadLoading,
  dispatch
) => {
  const formData = new FormData();
  media.forEach((file, index) => {
    formData.append(`media[${index}]`, file);
  });
  formData.append("job_id", id);

  setUploadLoading(true);

  dispatch(
    addMedia({
      data: formData,
      onSuccess: () => {
        setMedia([]);
        fetchJobData(setLoading);
        setUploadLoading(false);
      },
    })
  );
};

export const handleRemarksSubmit = (
  remarks,
  job_id,
  setRemarks,
  fetchJobData,
  setLoading,
  setUploadLoading,
  dispatch
) => {
  if (remarks === "") return;

  setUploadLoading(true);

  const data = {
    job_id: job_id,
    remarks: remarks,
  };

  dispatch(
    addRemarks({
      data: data,
      onSuccess: () => {
        setRemarks("");
        fetchJobData(setLoading);
        setUploadLoading(false);
      },
    })
  );
};

export const formatNumericInputWithComma = (inputValue) => {
  let value = String(inputValue);

  // Remove all non-numeric characters
  value = value.replace(/[^0-9]/g, "");

  // Add commas as thousands separators
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return value;
};

export const capitalizeWords = (str) => {
  if (!str) return "";
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const processFileDownload = (data, type, name, mimeType = 'application/pdf') => {
  let url = window.URL.createObjectURL(new Blob([data], {type: mimeType}));
  // eslint-disable-next-line default-case
  switch(type) {
      case 'open':
          window.open(url, '_blank');
          window.URL.revokeObjectURL(url);
          break;

      case 'download':
          let link = document.createElement('a');
          link.href = url;
          link.download = name;
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
          break;
  }
}
