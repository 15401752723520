import React, { useEffect, useState, useRef } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import {
  Button,
  capitalize,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backImg from "../../assets/images/backArrow.svg";
import optionsBtn from "../../assets/images/optionsBtn.svg";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../components/commonComponents/StaffTable";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getGarageStaff } from "../../redux-slices/usersSlice";
import { fetchServices, deleteService } from "../../redux-slices/servicesSlice";
import { fetchParts, deletePart } from "../../redux-slices/partsSlice";
import dayjs from "dayjs";
import UserDialog from "../../components/admin/UserDialog";
import ServiceDialog from "../../components/admin/ServiceDialog";
import PartDialog from "../../components/admin/PartDialog";
import { Link } from "react-router-dom";
import CustomPopover from "../../components/commonComponents/CustomerPopover";
import { Navbar } from "../../components/commonComponents/Navbar";

const Settings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { services } = useSelector((state) => state.services);
  const { parts } = useSelector((state) => state.parts);

  const [count, setCount] = useState(0);
  const [serviceCount, setServiceCount] = useState(0);
  const [partCount, setPartCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [allServices, setAllServices] = useState([]); // For client-side filtering fallback
  const [allParts, setAllParts] = useState([]); // For client-side filtering fallback
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [heading, setHeading] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedPart, setSelectedPart] = useState(null);
  const [tab, setTab] = useState(0);

  const getStaff = async (search = "") => {
    setLoading(true);
    const res = await dispatch(getGarageStaff({ search })).unwrap();
    setCount(res.count);
    setUsers(res.users);
    setLoading(false);
  };

  const getServiceData = async (search = "") => {
    setLoading(true);
    const res = await dispatch(fetchServices({ search })).unwrap();
    console.log("Service Data:", res);
    setServiceCount(res.length);
    setAllServices(res); // Store for client-side filtering
    setLoading(false);
  };

  const getPartsData = async (search = "") => {
    setLoading(true);
    const res = await dispatch(fetchParts({ search })).unwrap();
    console.log("Parts Data:", res);
    setPartCount(res.length);
    setAllParts(res); // Store for client-side filtering
    setLoading(false);
  };

  // Initial fetch for all counts
  useEffect(() => {
    const fetchAllCounts = async () => {
      await Promise.all([getStaff(), getServiceData(), getPartsData()]);
    };
    fetchAllCounts();
  }, [dispatch]);

  // Fetch data when tab changes
  useEffect(() => {
    if (tab === 0) {
      getStaff(searchValue);
    } else if (tab === 1) {
      getServiceData(searchValue);
    } else if (tab === 2) {
      getPartsData(searchValue);
    }
  }, [tab]);

  // Debounced search effect
  useEffect(() => {
    if (!isFirstRender.current) {
      const handle = setTimeout(() => {
        if (tab === 0) {
          getStaff(searchValue);
        } else if (tab === 1) {
          getServiceData(searchValue);
        } else if (tab === 2) {
          getPartsData(searchValue);
        }
      }, 1000);

      return () => {
        clearTimeout(handle);
      };
    } else {
      setTimeout(() => {
        isFirstRender.current = false;
      }, 1500);
    }
  }, [searchValue, tab]);

  const handleOptionsOpen = (event, item, type) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(true);
    if (type === "user") {
      setSelectedUser(item);
      setSelectedService(null);
      setSelectedPart(null);
    } else if (type === "service") {
      setSelectedService(item);
      setSelectedUser(null);
      setSelectedPart(null);
    } else if (type === "part") {
      setSelectedPart(item);
      setSelectedUser(null);
      setSelectedService(null);
    }
  };

  const handleAddUser = () => {
    setSelectedUser(null);
    setHeading("Add New User");
    setOpenModal(true);
  };

  const handleAddService = () => {
    setSelectedService(null);
    setHeading("Add New Service");
    setOpenModal(true);
  };

  const handleAddPart = () => {
    setSelectedPart(null);
    setHeading("Add New Part");
    setOpenModal(true);
  };

  const handleAction = (option) => {
    if (option.title === "Edit") {
      setHeading(
        selectedUser
          ? "Edit User"
          : selectedService
          ? "Edit Service"
          : "Edit Part"
      );
      setOpenModal(true);
    }
    if (option.title === "Delete") {
      if (selectedUser) {
        dispatch(
          deleteUser({
            id: selectedUser.id,
            onSuccess: () => {
              getStaff(searchValue);
            },
          })
        );
        setSelectedUser(null);
      } else if (selectedService) {
        dispatch(
          deleteService({
            id: selectedService.id,
            onSuccess: () => {
              getServiceData(searchValue);
            },
          })
        );
        setSelectedService(null);
      } else if (selectedPart) {
        dispatch(
          deletePart({
            id: selectedPart.id,
            onSuccess: () => {
              getPartsData(searchValue);
            },
          })
        );
        setSelectedPart(null);
      }
    }
    setOpenPopover(false);
  };

  const tabData = [
    { id: 0, val: "users", title: "Users", count: count },
    { id: 1, val: "services", title: "Services", count: serviceCount },
    { id: 2, val: "parts", title: "Parts", count: partCount },
  ];

  const userHeaders = [
    { id: "role", label: "Role" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "createdAt", label: "Date Created" },
    { id: "action", label: "Action" },
  ];

  const serviceHeaders = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "unit_price", label: "Unit Price" },
    { id: "action", label: "Action" },
  ];

  const partHeaders = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "cost_price", label: "Cost Price" },
    { id: "unit_price", label: "Unit Price" },
    { id: "action", label: "Action" },
  ];

  const popoverOptions = [{ title: "Edit" }, { title: "Delete" }];

  // Fallback client-side filtering for Services and Parts
  const filterData = (data, search) => {
    if (!search) return data;
    const lowerSearch = search.toLowerCase();
    return data.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(lowerSearch)
      )
    );
  };

 const renderUsersContent = () => {
   return (
     <>
       {users?.length > 0 && (
         <div
           className="mt-4 flex justify-between items-end"
           style={{ width: "1300px" }}
         >
           <Typography
             className="font-bold text-2xl"
             style={{ color: theme.palette.blue.main }}
           >
             ({count}) Users
           </Typography>
           <div className="flex justify-end">
             <Button
               className="h-10 md:w-32 text-white rounded-lg text-base transform -translate-x-22"
               style={{
                 textTransform: "none",
                 background: theme.palette.orange.main,
               }}
               onClick={handleAddUser}
             >
               Add User
             </Button>
           </div>
         </div>
       )}

       {users?.length > 0 && (
         <div className="mt-4">
           <TableContainer
             component={Paper}
             sx={{
               width: "1300px",
             }}
           >
             <Table sx={{ minWidth: "100%" }}>
               <TableHead>
                 <TableRow>
                   {userHeaders.map((header) => (
                     <StyledTableCell key={header.id} align="center">
                       {header.label}
                     </StyledTableCell>
                   ))}
                 </TableRow>
               </TableHead>
               <TableBody>
                 {users?.map((user) => (
                   <StyledTableRow key={user.id}>
                     <StyledTableCell align="center">
                       {capitalize(user.roles[0].name)}
                     </StyledTableCell>
                     <StyledTableCell align="center">
                       {user.name}
                     </StyledTableCell>
                     <StyledTableCell align="center">
                       {user.email}
                     </StyledTableCell>
                     <StyledTableCell align="center">
                       {dayjs(user.created_at).format("YYYY-MM-DD HH:mm:ss")}
                     </StyledTableCell>
                     <StyledTableCell align="center">
                       <div className="flex justify-center h-5">
                         <div
                           className="flex justify-center items-center w-1/3 cursor-pointer"
                           onClick={(event) =>
                             handleOptionsOpen(event, user, "user")
                           }
                         >
                           <img src={optionsBtn} alt="actions" />
                         </div>
                       </div>
                     </StyledTableCell>
                   </StyledTableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
         </div>
       )}
     </>
   );
 };

  const renderServicesContent = () => {
    // Fallback to client-side filtering if backend doesn't support search
    const displayedServices = filterData(allServices, searchValue);
    return (
      <>
        {displayedServices?.length > 0 && (
          <div
            className="mt-4 flex justify-between items-end"
            style={{ width: "1300px" }}
          >
            <Typography
              className="font-bold text-2xl"
              style={{ color: theme.palette.blue.main }}
            >
              ({serviceCount}) Services
            </Typography>
            <div className="flex justify-end">
              <Button
                className="h-10 md:w-32 text-white rounded-lg text-base transform -translate-x-22"
                style={{
                  textTransform: "none",
                  background: theme.palette.orange.main,
                }}
                onClick={handleAddService}
              >
                Add Service
              </Button>
            </div>
          </div>
        )}

        {displayedServices?.length > 0 && (
          <div className="mt-4">
            <TableContainer
              component={Paper}
              sx={{
                width: "1300px",
              }}
            >
              <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    {serviceHeaders.map((header) => (
                      <StyledTableCell key={header.id} align={header.id === "name" ? "left" : "center"}>
                        {header.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedServices?.map((service) => (
                    <StyledTableRow key={service.id}>
                      <StyledTableCell align="left">
                        {service.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {service.description}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {service.unit_price}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="flex justify-center h-5">
                          <div
                            className="flex justify-center items-center w-1/3 cursor-pointer"
                            onClick={(event) =>
                              handleOptionsOpen(event, service, "service")
                            }
                          >
                            <img src={optionsBtn} alt="actions" />
                          </div>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </>
    );
  };

  const renderPartsContent = () => {
    // Fallback to client-side filtering if backend doesn't support search
    const displayedParts = filterData(allParts, searchValue);
    return (
      <>
        {displayedParts?.length > 0 && (
          <div
            className="mt-4 flex justify-between items-end"
            style={{ width: "1300px" }}
          >
            <Typography
              className="font-bold text-2xl"
              style={{ color: theme.palette.blue.main }}
            >
              ({partCount}) Parts
            </Typography>
            <div className="flex justify-end">
              <Button
                className="h-10 md:w-32 text-white rounded-lg text-base transform -translate-x-27"
                style={{
                  textTransform: "none",
                  background: theme.palette.orange.main,
                }}
                onClick={handleAddPart}
              >
                Add Part
              </Button>
            </div>
          </div>
        )}

        {displayedParts?.length > 0 && (
          <div className="mt-4">
            <TableContainer
              component={Paper}
              sx={{
                width: "1300px",
              }}
            >
              <Table sx={{ minWidth: "100%" }}>
                <TableHead>
                  <TableRow>
                    {partHeaders.map((header) => (
                      <StyledTableCell key={header.id} align={header.id === "name" || header.id === "description" ? "left" : "center"}>
                        {header.label}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedParts?.map((part) => (
                    <StyledTableRow key={part.id}>
                      <StyledTableCell align="left">
                        {part.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {part.description}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {part.cost_price}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {part.unit_price}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="flex justify-center h-5">
                          <div
                            className="flex justify-center items-center w-1/3 cursor-pointer"
                            onClick={(event) =>
                              handleOptionsOpen(event, part, "part")
                            }
                          >
                            <img src={optionsBtn} alt="actions" />
                          </div>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </>
    );
  };

  return (
    (document.title = "Carfix Curacao | Admin"),
    (
      <StaffLayout setTab={setTab} defaultTab={0}>
        <div className="mx-7 md:mx-10 py-7">
          <div className="flex justify-between items-center w-48">
            <Link className="flex justify-center" to="/admin">
              <img className="cursor-pointer" src={backImg} alt="back" />
            </Link>
            <Typography
              className="font-bold text-3xl"
              style={{ color: theme.palette.blue.main }}
            >
              Settings
            </Typography>
          </div>
          <div>
            <div className="tabs-container">
              <Navbar
                tab={tab}
                setTab={setTab}
                tabData={tabData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                settingsContext={true}
                staffCount={count}
                serviceCount={serviceCount}
                partCount={partCount}
              />
            </div>
            {loading ? (
              <CircularProgress
                sx={{ color: theme.palette.orange.main }}
                className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              />
            ) : (
              <>
                {tab === 0 && renderUsersContent()}
                {tab === 1 && renderServicesContent()}
                {tab === 2 && renderPartsContent()}
              </>
            )}
          </div>
          <CustomPopover
            openPopover={openPopover}
            setOpenPopover={setOpenPopover}
            anchorEl={anchorEl}
            popoverOptions={popoverOptions}
            handleAction={handleAction}
          />
          <UserDialog
            open={openModal}
            setOpen={setOpenModal}
            heading={heading}
            user={selectedUser}
            service={selectedService}
            part={selectedPart}
            getStaff={getStaff}
            getServices={getServiceData}
            getParts={getPartsData}
          />
          <ServiceDialog
            open={
              openModal &&
              (heading === "Add New Service" || heading === "Edit Service")
            }
            setOpen={setOpenModal}
            heading={heading}
            service={selectedService}
            getServices={getServiceData}
          />
          <PartDialog
            open={
              openModal &&
              (heading === "Add New Part" || heading === "Edit Part")
            }
            setOpen={setOpenModal}
            heading={heading}
            part={selectedPart}
            getParts={getPartsData}
          />
        </div>
      </StaffLayout>
    )
  );
};

export default Settings;
