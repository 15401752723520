import { CircularProgress, ThemeProvider, createTheme } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { getUser } from "./redux-slices/authSlice";

const theme = createTheme({
  palette: {
    orange: {
      main: "#F3A366",
    },
    blue: {
      main: "#171C36",
    },
    white: {
      main: "#FFFFFF",
    },
    red: {
      main: "#FB7D7D",
    },

    text: {
      darkBlue: "#171C36",
      white: "#FFFFFF",
      placeholder: "#9FA3AF",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
    fontSize: 12,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1281,
      xlg: 1950,
      xxl: 2560,
    },
  },
});

function App() {
  const dispatch = useDispatch();

  const { user, userLoading } = useSelector((state) => state.auth);

  const publicRoutes = router.routes
    .filter((route) => route.public)
    .map((route) => route.path);

  useEffect(() => {
    if (user && !userLoading) {
      if (window.location.pathname === "/login") {
        switch (user.roles[0].name) {
          case "admin":
            window.location = "/admin";
            break;
          case "receptionist":
            window.location = "/receptionist";
            break;
          case "supervisor":
            window.location = "/supervisor";
            break;
          case "mechanic":
            window.location = "/mechanic";
            break;
          case "driver":
            window.location = "/driver";
            break;
          default:
            window.location = "/login";
            break;
        }
      }
    }

    if (!user) {
      dispatch(
        getUser({
          onFail: () => {
            if (!publicRoutes.includes(window.location.pathname))
              window.location = "/login";
          },
        })
      );
    }
  }, [dispatch, publicRoutes, user, userLoading]);

  if (userLoading)
    return (
      <CircularProgress
        sx={{ color: theme.palette.orange.main }}
        className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
      />
    );

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-center" />
    </ThemeProvider>
  );
}

export default App;
