import React, { useEffect, useState, useRef } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import { StaffTable } from "../../components/commonComponents/StaffTable";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../redux-slices/jobsSlice";
import arrowdown from "../../assets/images/arrowDownWhite.svg";
import sortIcon from "../../assets/images/sort.svg";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Navbar } from "../../components/commonComponents/Navbar";
import { getGeneralPickupOrders } from "../../redux-slices/generalPickupOrderSlice";
import { DriverTable } from "../../components/driverFlow/DriverTable";

export const Admin = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { jobs } = useSelector((state) => state.jobs);
  const { generalPickupOrders } = useSelector((state) => state.generalPickup);

  const [limit, setLimit] = useState(20);
  const [tab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState([]);
  const [assignedUser, setAssignedUser] = useState();
  const [assignedToFilter, setAssignedToFilter] = useState("anyone");
  const [statusFilter, setStatusFilter] = useState("any");
  const [filter, setFilter] = useState(0);
  const [selectedUser, setSelectedUser] = useState("Anyone");
  const isFirstRender = useRef(true);
  const prevFilter = useRef(filter);

  const fetchData = (search) => {
    setLoading(true);
    if (tab === 0) {
      dispatch(
        getJobs({
          filter: filterOptions[filter].val,
          assigned_to_filter: assignedToFilter,
          assigned_to_user : assignedUser,
          search,
          sort,
          limit,
        })
      ).then(() => setLoading(false));
    } else {
      dispatch(
        getGeneralPickupOrders({
          filter: filterOptions[filter].val,
          status_filter: statusFilter,
          search,
          sort,
          limit,
        })
      ).then(() => setLoading(false));
    }
  };

  useEffect(() => {
    setSort([]);
    setAssignedToFilter("anyone");
    setSelectedUser("Anyone");
    setAssignedUser();
    setStatusFilter("any");
    setLimit(20);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab, filter]);

  useEffect(() => {
    if(!isFirstRender.current){
      const handle = setTimeout(() => {
        fetchData(searchValue);
      }, 1000);
  
      return () => {
        clearTimeout(handle);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if(!isFirstRender.current){
      // to reduce unnecessary api calls
      if(prevFilter.current === filter){
        fetchData();
      }else{
        prevFilter.current = filter;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filter, assignedToFilter, assignedUser, limit, statusFilter]);

  useEffect(()=>{
      setTimeout(() => {
        isFirstRender.current = false;
      }, 1500);
    }, []);

  const updateSorting = (headerId) => {
    const newSort = [...sort];
    const column =
      tab === 0
        ? serviceOrderHeaderTitles.find((h) => h.id === headerId).val
        : generalOrderHeaderTitles.find((h) => h.id === headerId).val;
    const index = newSort.indexOf(column);

    if (index === -1) {
      newSort.push(column);
    } else {
      newSort.splice(index, 1);
    }

    setSort(newSort);
  };

  const handleFilterSelect = (event) => {
    setFilter(event.target.value);
  };

  const tabData = [
    { id: 0, val: "serviceorders", title: "Service Orders" },
    { id: 1, val: "generalorders", title: "Pickup Orders" },
  ];

  const filterOptions = [
    { id: 0, title: "All Service Orders", val: "all" },
    { id: 1, title: "New Jobs", val: "new-order" },
    { id: 2, title: "Awaiting Parts", val: "awaiting-parts" },
    { id: 3, title: "To Be Assigned", val: "to-be-assigned" },
    { id: 4, title: "Awaiting Mechanic", val: "awaiting-mechanic" },
    { id: 5, title: "In Progress", val: "in-progress" },
    { id: 6, title: "Awaiting Review", val: "awaiting-review" },
    { id: 7, title: "To Be Invoiced ", val: "to-be-invoiced" },
    { id: 8, title: "Invoiced ", val: "invoiced" },
    { id: 9, title: "Completed", val: "completed" },
    { id: 10, title: "Cancelled", val: "cancelled" },
  ];

  const serviceOrderHeaderTitles = [
    { id: 0, title: "Order ID" },
    { id: 1, title: "Date & Time", img: sortIcon, val: "created_at" },
    { id: 2, title: "Duration in Garage" },
    { id: 3, title: "Make & Model" },
    { id: 4, title: "License Plate" },
    { id: 5, title: "Description" },
    { id: 8, title: "Invoice Number" },
    { id: 6, title: "Assigned To", img: arrowdown, val: "assigned_to" },
    { id: 7, title: "Status", val: "status" },
  ];

  const generalOrderHeaderTitles = [
    { id: 1, title: "Pick-Up #", show: true },
    {
      id: 2,
      title: "Assigned at",
      img: true,
      val: "created_at",
      show: true,
    },
    { id: 3, title: "Description", show: true },
    { id: 7, title: "Assigned By", show: true},
    { id: 4, title: "Assigned To", show: true },
    { id: 5, title: "Status", img: true, val: "status", show: true },
    { id: 6, title: "Parts", show: true },
  ];

  return (
    (document.title = "Carfix Curacao | Admin"),
    (
      <StaffLayout setTab={setTab} defaultTab={0}>
        <Navbar
          tab={tab}
          setTab={setTab}
          tabData={tabData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filter={filter}
          filterOptions={filterOptions}
          handleFilterSelect={handleFilterSelect}
        />
        {loading ? (
          <CircularProgress
            sx={{ color: theme.palette.orange.main }}
            className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
          />
        ) : tab === 0 ? (
          <StaffTable
            jobs={jobs}
            headerTitles={serviceOrderHeaderTitles}
            sort={sort}
            filter={filter}
            filterOptions={filterOptions}
            setSorting={updateSorting}
            fetchData={fetchData}
            setAssignedToFilter={setAssignedToFilter}
            setAssignedToUser={setAssignedUser}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setLimit={setLimit}
            showDescription
            showDuration
            showAssignedTo
            showInvoiceNumber={filter === 0 || filter === 8 || filter === 9}
            showStatus
          />
        ) : (
          <DriverTable
            jobs={generalPickupOrders}
            headerTitles={generalOrderHeaderTitles}
            sort={sort}
            setSorting={updateSorting}
            setStatusFilter={setStatusFilter}
            fetchData={fetchData}
            setLimit={setLimit}
            tab={tab}
            showPickupNumber
            showDescription
            showAssignedTo
            showStatus
            showAssignedBy
          />
        )}
      </StaffLayout>
    )
  );
};
