import React from "react";
import { Grid } from "@mui/material";
import ModalImage from "react-modal-image";
import deleteImg from "../../assets/images/imageDelete.svg";
import { useDispatch } from "react-redux";
import { deleteMedia } from "../../redux-slices/jobsSlice";

export const Gallery = ({ showDelete, itemData, fetchJobData }) => {
  const dispatch = useDispatch();

  const handleDelete = (id) => () => {
    dispatch(deleteMedia({ id, onSuccess: fetchJobData }));
  };

  return (
    <Grid container className="w-full" spacing={2}>
      {itemData?.map((item, index) => (
        <Grid key={index} item xs={6} md={4} lg={3} className="relative">
          {" "}
          <ModalImage
            hideDownload
            showRotate
            loading="lazy"
            className={`rounded-lg w-36 h-36 object-cover ${
              showDelete ? "border border-[#F3A366]" : ""
            }`}
            small={`${process.env.REACT_APP_API_IMG_URL}/${item?.path}`}
            large={`${process.env.REACT_APP_API_IMG_URL}/${item?.path}`}
          />
          {showDelete && (
            <img
              src={deleteImg}
              alt="Delete"
              className="absolute top-[8px] left-[8px] cursor-pointer h-4 w-4"
              onClick={handleDelete(item?.id)}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};
