import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  closeJobModal,
  openJobModal,
  showJobModalActions,
} from "../../redux-slices/modalSlice";
import { getDriverJobs } from "../../redux-slices/jobsSlice";
import {
  StyledTableCell,
  StyledTableRow,
} from "../commonComponents/StaffTable";
import dayjs from "dayjs";
import DriverModalDetails from "./DriverModalDetails";
import {
  capitalizeWords,
  formatStatus,
  getStatusColor,
} from "../../utils/helpers";
import CreatePickupOrderModal from "../commonComponents/CreatePickupOrderModal";
import arrowdown from "../../assets/images/arrowDownWhite.svg";
import CustomPopover from "../commonComponents/CustomerPopover";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPickupOrdersPdf } from "../../redux-slices/pdfSlice";

export const DriverTableAdjusted = ({
  jobs = [],
  headerTitles,
  sort,
  setSorting,
  setStatusFilter,
  fetchData,
  setLimit,
  tab,
  showPickupNumber,
  showServiceOrder,
  showMakeAndModel,
  showDescription,
  showAssignedBy,
  showAssignedTo,
  showStatus,
}) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { jobModalOpen } = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const [id, setId] = useState(null);
  const [headerPopOver, setHeaderPopOver] = useState(false);
  const [headerPopOverAnchorEl, setHeaderPopOverAnchorEl] = useState(null);
  const [showPickupOrderModal, setShowPickupOrderModal] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);

  // Debugging logs for props
  console.log("showPickupNumber:", showPickupNumber);
  console.log("showServiceOrder:", showServiceOrder);
  console.log("showStatus:", showStatus);
  console.log("headerTitles:", headerTitles);

  const openModal = (row) => {
    setId(row.job_id ?? row.id);
    dispatch(showJobModalActions());
    dispatch(openJobModal({ id: row.job_id ?? row.id }));
  };

  const closeModal = () => {
    dispatch(closeJobModal());
    dispatch(getDriverJobs({ tab }));
  };

  useEffect(() => {
    if (!jobModalOpen && shouldFetchData) {
      console.log("Fetching parent data after modal close");
      fetchData();
      setShouldFetchData(false);
    }
  }, [jobModalOpen, shouldFetchData, fetchData]);

  useEffect(() => {
    if (jobModalOpen) {
      setShouldFetchData(true);
    }
  }, [jobModalOpen]);

  useEffect(() => {
    if (jobs === null) {
      console.log("Jobs is null, calling fetchData");
      fetchData();
    }
  }, [jobs, fetchData]);

  useEffect(() => {
    console.log("Jobs prop in DriverTable:", jobs);
  }, [jobs]);

  const handleArrowDownClick = (e, header) => {
    const target = e.target;
    if (header.val === "status") {
      setHeaderPopOverAnchorEl(target);
      setHeaderPopOver(true);
    } else {
      target.classList.toggle("rotate-180");
      setSorting(header.id);
    }
  };

  const popoverOptions = [
    { title: "All", val: "any" },
    { title: "Pending", val: "pending" },
    { title: "Completed", val: "completed" },
    { title: "Cancelled", val: "cancelled" },
  ];

  const handleHeaderPopOverOptionClick = (option) => {
    setStatusFilter(option.val);
    setHeaderPopOver(false);
  };

  const handlePickupOrdersPrint = () => {
    dispatch(getPickupOrdersPdf(tab));
  };

  const jobArray =
    Array.isArray(jobs) && jobs.length > 0 && Array.isArray(jobs[0])
      ? jobs[0]
      : [];
  const totalCount =
    Array.isArray(jobs) && jobs.length > 1 ? jobs[1]?.total_count || 0 : 0;

  return (
    <div className="mx-7 md:mx-10">
      <div
        className={`flex items-center mb-5 py-0 ${
          user.roles[0].name !== "driver" ? "justify-between" : ""
        }`}
      >
        <div className="flex items-center">
          <Typography
            className="text-3xl"
            style={{ color: theme.palette.blue.main }}
          >
            {tab === 0 ? "Service Orders" : "Pickup Orders"}
          </Typography>
          {user.roles[0].name === "receptionist" && (
            <div className="ml-4">
              <img
                src={PrintImg}
                alt="PrintImg"
                className="cursor-pointer"
                onClick={() => dispatch(getPickupOrdersPdf(1))}
              />
            </div>
          )}
        </div>
        {user.roles[0].name !== "driver" && (
          <div className="w-48">
            <Button
              className="w-full h-12 text-white rounded-xl text-base"
              style={{
                background: theme.palette.orange.main,
                textTransform: "none",
              }}
              onClick={() => setShowPickupOrderModal(true)}
            >
              Create Pickup Order
            </Button>
          </div>
        )}
        {user.roles[0].name === "driver" && (
          <div className="ml-4">
            <img
              src={PrintImg}
              alt="PrintImg"
              className="cursor-pointer"
              onClick={handlePickupOrdersPrint}
            />
          </div>
        )}
      </div>
      <div className="mb-10">
        <TableContainer component={Paper} sx={{ width: "1300px" }}>
          <Table sx={{ width: "100%" }}>
            <TableHead>
            <TableRow>
                {headerTitles?.map((header) => (
                  <StyledTableCell
                    className={`
                       ${header.id === 6 ? "w-60" : "w-28"}
                       ${!header.show && "hidden"}
                       `}
                    key={header.id}
                  >
                    {header.id === 6 ? (
                      <div className="flex justify-between items-center h-5 w-full px-1">
                        <div style={{ width: "30%" }}>
                          <Typography className="text-white text-xs">
                            Name
                          </Typography>
                        </div>
                        <div style={{ width: "20%" }}>
                          <Typography className="text-white text-xs text-center">
                            Qty
                          </Typography>
                        </div>
                        <div style={{ width: "40%" }}>
                          <Typography className="text-white text-xs text-center">
                            Location
                          </Typography>
                        </div>
                        <div style={{ width: "20%" }}>
                          <Typography className="text-white text-xs text-center">
                            Status
                          </Typography>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`flex ${
                          header.title === "Status"
                            ? "justify-start pl-8"
                            : "justify-center"
                        }`}
                      >
                        {header.title}
                        {header.img && (
                          <img
                            src={arrowdown}
                            className={`ml-2 cursor-pointer ${
                              sort.includes(header.val) ? "rotate-180" : ""
                            }`}
                            alt=""
                            onClick={(e) => handleArrowDownClick(e, header)}
                          />
                        )}
                      </div>
                    )}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobArray.length > 0 ? (
                jobArray.map((row) => {
                  // Debugging log for each row
                  console.log("Row in jobArray:", row);
                  // Debugging log for parts
                  console.log("Row parts:", row.parts);
                  return (
                    <StyledTableRow
                      key={row.job_id || row.id}
                      onClick={() => openModal(row)}
                      className="cursor-pointer"
                      sx={{ height: "60px" }}
                    >
                      {headerTitles.map((header) => {
                        if (!header.show) return null;
                        return (
                          <StyledTableCell
                            align="center"
                            key={header.id}
                            className={
                              header.id === 5 ||
                              header.id === 6 ||
                              header.id === 7 ||
                              header.id === 8
                                ? "p-0 w-60"
                                : ""
                            }
                            sx={{
                              padding: "12px 16px",
                              verticalAlign: "middle",
                            }}
                          >
                            {(header.id === 0 || header.id === 1) && (
                              <Typography
                              style={{ color: theme.palette.blue.main }}
                            >
                              { tab === 0 ? row.display_id : row.id || "N/A"}
                            </Typography>
                            )}
                            {header.id === 2 && (
                              <Typography
                                style={{ color: theme.palette.blue.main }}
                              >
                                {row.date_time
                                  ? dayjs(row.date_time).format(
                                      "YYYY:MM:DD HH:mm"
                                    )
                                  : "N/A"}
                              </Typography>
                            )}
                            {header.id === 3 && showMakeAndModel && (
                              <Typography
                                style={{ color: theme.palette.blue.main }}
                              >
                                {row.make_and_model || "N/A"}
                              </Typography>
                            )}
                            {header.id === 4 && (
                              <Typography
                                style={{ color: theme.palette.blue.main }}
                              >
                                {row.chassis_number || "N/A"}
                              </Typography>
                            )}
                            {header.id === 5 && (
                              <Typography
                              style={{ color: theme.palette.blue.main }}
                            >
                              {row.description || "N/A"}
                            </Typography>
                            )}
                            {(
                              header.id === 6 ||
                              header.id === 7 ||
                              header.id === 8) && (
                              <div className="flex flex-col w-full">
                                {(row.parts || []).map((item, index) => {
                                  // Debugging log for each part
                                  console.log("Part item:", item);
                                  return (
                                    <div
                                      key={item.id || index}
                                      className="flex justify-between items-center w-full px-5 py-3"
                                    > 
                                      <div
                                        style={{
                                          width: "30%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          {item.name || "N/A"}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          width: "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          {item.quantity || "N/A"}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          width: "40%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          {item.location || "N/A"}
                                        </Typography>
                                      </div>
                                      <div
                                        style={{
                                          width: "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          {item.status || "N/A"}
                                        </Typography>
                                      </div>
                                    </div>
                                  );
                                })}
                                {(!row.parts || row.parts.length === 0) && (
                                  <div className="flex justify-between items-center w-full px-5 py-3">
                                    {header.id === 5 && (
                                      <div
                                        style={{
                                          width: "30%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          N/A
                                        </Typography>
                                      </div>
                                    )}
                                    {header.id === 6 && (
                                      <div
                                        style={{
                                          width: "10%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          N/A
                                        </Typography>
                                      </div>
                                    )}
                                    {header.id === 7 && (
                                      <div
                                        style={{
                                          width: "40%",
                                          textAlign: "left",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          N/A
                                        </Typography>
                                      </div>
                                    )}
                                    {header.id === 8 && showStatus && (
                                      <div
                                        style={{
                                          width: "20%",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: theme.palette.blue.main,
                                          }}
                                        >
                                          N/A
                                        </Typography>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={headerTitles.length} align="center">
                    <Typography style={{ color: theme.palette.blue.main }}>
                      No data available
                    </Typography>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {jobArray.length > 0 && jobArray.length < totalCount && (
          <div className="flex justify-end mt-2">
            <Typography
              className="font-bold text-base cursor-pointer"
              style={{ color: theme.palette.blue.main }}
              onClick={() => setLimit((prev) => prev + 20)}
            >
              See more {">>"}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <CustomPopover
          openPopover={headerPopOver}
          setOpenPopover={setHeaderPopOver}
          anchorEl={headerPopOverAnchorEl}
          popoverOptions={popoverOptions}
          handleAction={handleHeaderPopOverOptionClick}
        />
      </div>
      <DriverModalDetails
        id={id}
        tab={tab}
        closeModal={closeModal}
        fetchData={fetchData}
      />
      <CreatePickupOrderModal
        open={showPickupOrderModal}
        handleClose={() => setShowPickupOrderModal(false)}
        fetchData={fetchData}
      />
    </div>
  );
};
