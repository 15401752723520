import React, { useEffect, useState } from "react";
import { StaffLayout } from "./layouts/StaffLayout";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backImg from "../assets/images/backArrow.svg";
import {
  StyledTableCell,
  StyledTableRow,
} from "../components/commonComponents/StaffTable";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getAllNotifications } from "../redux-slices/notificationSlice";

const Notifications = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limit = 10;

  const [count, setCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);

  const fetchMoreNotifications = async () => {
    if (loading || !hasMore) return;

    setFetchMoreLoading(true);
    try {
      const result = await dispatch(
        getAllNotifications({ limit, offset })
      ).unwrap();
      if (result.notifications.length > 0) {
        setNotifications((prev) => [...prev, ...result.notifications]);
        setOffset((prev) => prev + limit);
        if (result.notifications.length < limit) setHasMore(false);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching more notifications:", error);
    } finally {
      setFetchMoreLoading(false);
    }
  };

  useEffect(() => {
    const fetchInitialNotifications = async () => {
      try {
        const result = await dispatch(
          getAllNotifications({ limit, offset: 0 })
        ).unwrap();
        setNotifications(result.notifications);
        setCount(result.totalNotificationCount);
        setOffset(limit);
        if (result.notifications.length < limit) setHasMore(false);
      } catch (error) {
        console.error("Error fetching initial notifications:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialNotifications();
  }, [dispatch, limit]);

  const headers = [
    { id: "id", label: "Id" },
    { id: "timestamp", label: "Date and Time" },
    { id: "description", label: "Notification" },
  ];

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    (document.title = "Carfix Curacao | Admin"),
    (
      <StaffLayout>
        <div className="mx-7 md:mx-10">
          <div className="flex items-center py-10">
            <div className="flex justify-center" onClick={handleGoBack}>
              <img className="cursor-pointer" src={backImg} alt="back" />
            </div>
            <Typography
              className="ml-4 font-bold text-3xl"
              style={{ color: theme.palette.blue.main }}
            >
              All Notifications
            </Typography>
          </div>

          {notifications.length > 0 && (
            <div>
              <Typography
                className="font-bold text-3xl"
                style={{ color: theme.palette.blue.main }}
              >
                ({count}) Notifications
              </Typography>
            </div>
          )}

          <div className="mt-4 mb-4">
            {loading ? (
              <CircularProgress
                sx={{ color: theme.palette.orange.main }}
                className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
              />
            ) : (
              <TableContainer
                className={`${fetchMoreLoading ? "opacity-50" : ""}`}
                component={Paper}
              >
                {fetchMoreLoading && (
                  <CircularProgress
                    sx={{ color: theme.palette.orange.main }}
                    className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
                  />
                )}
                <Table sx={{ minWidth: 700 }}>
                  <TableHead>
                    <TableRow>
                      {headers.map((header) => (
                        <StyledTableCell
                          className={`
                            ${header.id === "description" ? "w-3/4" : ""}
                            ${header.id === "timestamp" ? "w-1/4" : ""}
                          ${
                            header.id === "description"
                              ? "text-start pl-5"
                              : "text-center"
                          }`}
                          key={header.id}
                          align="center"
                        >
                          {header.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notifications.length > 0 ? (
                      notifications.map((notification, index) => (
                        <StyledTableRow key={notification.id}>
                          <StyledTableCell align="center">
                            {index + 1}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {dayjs(notification.created_at).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="left">
                            <Typography className="font-bold">
                              {notification.title}
                            </Typography>
                            <Typography>{notification.message}</Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell align="center" colSpan={2}>
                          No Notifications found
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
          {notifications.length < count && notifications.length > 0 && (
            <div className="flex justify-end my-2">
              <Typography
                className="font-bold text-base cursor-pointer"
                style={{ color: theme.palette.blue.main }}
                onClick={() => fetchMoreNotifications()}
              >
                See more {">>"}
              </Typography>
            </div>
          )}
        </div>
      </StaffLayout>
    )
  );
};

export default Notifications;
