import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { ArrowDropDownRounded } from "@mui/icons-material";
import addWhiteImg from "../../assets/images/addWhite.svg";
import deleteImg from "../../assets/images/delete.svg";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { addPart, deletePart, updatePart } from "../../redux-slices/jobsSlice";
import { useDispatch } from "react-redux";
import { formatStatus, dropdownFromOptions } from "../../utils/helpers";

export const ReceptionistModalTable = ({
  tab,
  headerTitles,
  data,
  status,
  user_id,
  partBy,
  job_id,
  fetchJobData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [parts, setParts] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [newPartLocation, setNewPartLocation] = useState("");
  const [newPartQuantity, setNewPartQuantity] = useState("");
  const [newPartName, setNewPartName] = useState("");
  const [newPartOrigin, setNewPartOrigin] = useState("user");
  const [isAdding, setIsAdding] = useState(false); // Track if "+" button is clicked

  useEffect(() => {
    console.log("Data received:", data); // Debugging
    if (!data) {
      setParts([]);
      return;
    }
    const formattedData = data.map((item) => ({
      location: item.location || "N/A",
      part: item.name,
      quantity: item.quantity,
      from: item.from,
      status: item.status,
    }));
    console.log("Formatted parts:", formattedData); // Debugging
    setParts(formattedData);
  }, [data]);

  const handleChange = (index, field, value) => {
    const newParts = [...parts];
    if (field === "quantity" && value < 0) return;
    newParts[index][field] = value;
    setParts(newParts);
  };

  const insertRow = () => {
    const hiddenRow = document.getElementById(`hidden-row-${partBy}`);
    const newRowBtn = document.getElementById(`new-row-btn-${partBy}`);
    if (hiddenRow && newRowBtn) {
      hiddenRow.classList.remove("hidden");
      newRowBtn.classList.add("hidden");
      setIsAdding(true); // Set isAdding to true when "+" is clicked
    } else {
      console.error(
        "Hidden row or new row button not found for partBy:",
        partBy
      );
    }
  };

  const deleteNewRow = () => {
    const hiddenRow = document.getElementById(`hidden-row-${partBy}`);
    const newRowBtn = document.getElementById(`new-row-btn-${partBy}`);
    if (hiddenRow && newRowBtn) {
      hiddenRow.classList.add("hidden");
      newRowBtn.classList.remove("hidden");
      if (parts.length === 0) {
        setIsAdding(false); // Reset isAdding if no parts are added
      }
    }
  };

  const addNewPart = () => {
    const hiddenRow = document.getElementById(`hidden-row-${partBy}`);
    const newRowBtn = document.getElementById(`new-row-btn-${partBy}`);
    const newPart = {
      location: newPartLocation,
      part: newPartName,
      quantity: newPartQuantity,
      from: partBy === "customer" ? newPartOrigin : "",
      status: partBy === "customer" ? "available" : "pending",
    };
    if (!newPart.part || !newPart.quantity || newPart.quantity == 0) {
      alert("Part Name and Quantity are required");
      return;
    }
    setParts([...parts, newPart]);
    dispatch(
      addPart({
        data: {
          location: newPart.location,
          name: newPart.part,
          quantity: newPart.quantity,
          from: newPart.from,
          status: partBy === "customer" ? "available" : "pending",
          job_id: job_id,
          partBy: user_id,
        },
        onSuccess: () => fetchJobData(),
      })
    );
    if (hiddenRow && newRowBtn) {
      hiddenRow.classList.add("hidden");
      newRowBtn.classList.remove("hidden");
    }
    setNewPartLocation("");
    setNewPartName("");
    setNewPartQuantity("");
  };

  const deleteRow = (index, id) => {
    const newParts = [...parts];
    newParts.splice(index, 1);
    setParts(newParts);
    if (newParts.length === 0) {
      setIsAdding(false); // Reset isAdding if all parts are deleted
    }
    if (data[index].id) {
      dispatch(deletePart(data[index].id));
    }
  };

  const editRow = (index) => {
    setEditIndex(index);
  };

  const saveRow = (index, id) => {
    if (
      !parts[index].part ||
      !parts[index].quantity ||
      parts[index].quantity == 0
    ) {
      alert("Part Name and Quantity are required");
      return;
    } else if (
      parts[index].part === data[index].name &&
      parts[index].quantity === data[index].quantity &&
      parts[index].from === data[index].from &&
      parts[index].location === data[index].location
    ) {
      setEditIndex(null);
      return;
    } else {
      setEditIndex(null);
      dispatch(
        updatePart({
          id,
          data: {
            location: parts[index].location,
            name: parts[index].part,
            quantity: parts[index].quantity,
            from: parts[index].from,
            status: parts[index].status,
          },
          onSuccess: () => fetchJobData(),
        })
      );
    }
  };

  // Determine the number of columns for colSpan
  const baseColumns = partBy === "customer" ? 3 : 4; // 3 for "customer" (Name, Quantity, From), 4 for "garage" (Location, Name, Quantity, Status)
  const totalColumns =
    (status === "new-order" || status === "to-be-assigned") &&
    (isAdding || parts.length > 0)
      ? baseColumns + 1
      : baseColumns;

  return (
    <div>
      <TableContainer
        className="rounded-lg mt-2 mb-5 shadow-md"
        component={Paper}
      >
        <Table
          sx={{
            tableLayout: "fixed",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <TableHead
            sx={{ backgroundColor: theme.palette.blue.main, width: "100%" }}
          >
            <TableRow>
              {headerTitles.map((header) => (
                <TableCell
                  key={header.id}
                  className={`text-white text-xs ${
                    header.id === 0 ? "w-[30%]" : ""
                  } ${header.id === 1 ? "w-[25%]" : ""} // Name
                  ${
                    header.id === 2
                      ? partBy !== "customer"
                        ? "w-[15%]"
                        : "hidden"
                      : ""
                  } // Quantity
                  ${
                    header.id === 3
                      ? partBy !== "customer"
                        ? "w-[20%]"
                        : "hidden"
                      : ""
                  } // Status
                  ${
                    header.id === 4
                      ? partBy === "customer"
                        ? "w-[20%]"
                        : "hidden"
                      : ""
                  }`} // From
                  sx={{
                    padding: "4px 8px",
                    textAlign: "center",
                  }}
                >
                  {header.title}
                </TableCell>
              ))}
              {(status === "new-order" || status === "to-be-assigned") &&
                (isAdding || parts.length > 0) && (
                  <TableCell
                    className="w-[10%] text-xs"
                    sx={{ padding: "4px 8px", textAlign: "center" }}
                  ></TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.length > 0
              ? parts.map((part, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {partBy !== "customer" && (
                      <TableCell
                        className="w-[30%]"
                        sx={{ padding: "4px 8px", textAlign: "center" }}
                      >
                        {editIndex !== index ? (
                          <Typography className="text-xs text-center">
                            {part?.location || "N/A"}
                          </Typography>
                        ) : (
                          <input
                            className="w-full font-normal text-xs bg-white px-2 py-1"
                            value={part?.location}
                            onChange={(e) =>
                              handleChange(index, "location", e.target.value)
                            }
                            placeholder="Location"
                          />
                        )}
                      </TableCell>
                    )}
                    <TableCell
                      className="w-[25%]"
                      sx={{ padding: "4px 8px", textAlign: "center" }}
                    >
                      {editIndex !== index ? (
                        <Typography className="text-xs text-center">
                          {part.part}
                        </Typography>
                      ) : (
                        <input
                          className="w-full font-normal text-xs bg-white px-2 py-1"
                          value={part.part}
                          onChange={(e) =>
                            handleChange(index, "part", e.target.value)
                          }
                          placeholder="Part"
                        />
                      )}
                    </TableCell>
                    <TableCell
                      className="w-[15%]"
                      sx={{ padding: "4px 8px", textAlign: "center" }}
                    >
                      <input
                        className="w-full text-center font-normal text-xs bg-white px-2 py-1"
                        type="number"
                        
                        value={part.quantity}
                        onChange={(e) =>
                          handleChange(index, "quantity", e.target.value)
                        }
                        placeholder="qty"
                        disabled={editIndex !== index}
                      />
                    </TableCell>
                    {partBy === "customer" && (
                      <TableCell
                        className="w-[20%]"
                        sx={{ padding: "4px 8px", textAlign: "center" }}
                      >
                        {editIndex !== index ? (
                          <Typography className="text-xs text-center">
                            {part.from?.charAt(0).toUpperCase() +
                              part.from?.slice(1)}
                          </Typography>
                        ) : (
                          <div className="relative">
                            <select
                              className="w-full text-center font-normal text-xs bg-white px-2 py-1 appearance-none"
                              value={part.from}
                              onChange={(e) =>
                                handleChange(index, "from", e.target.value)
                              }
                              disabled={editIndex !== index}
                            >
                              {dropdownFromOptions.map((option, idx) => (
                                <option key={idx} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {editIndex === index && (
                              <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                                <ArrowDropDownRounded />
                              </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    )}
                    {partBy !== "customer" && (
                      <TableCell
                        className="w-[20%]"
                        sx={{ padding: "4px 8px", textAlign: "center" }}
                      >
                        <Typography className="text-center text-xs">
                          {formatStatus(part.status)}
                        </Typography>
                      </TableCell>
                    )}
                    {(status === "new-order" || status === "to-be-assigned") &&
                      (isAdding || parts.length > 0) && (
                        <TableCell
                          className="w-[10%]"
                          sx={{ padding: "4px 8px", textAlign: "center" }}
                        >
                          <div className="flex justify-center gap-2">
                            {editIndex === index ? (
                              <img
                                className="cursor-pointer h-3 w-3"
                                src={saveImg}
                                alt="save"
                                onClick={() => saveRow(index, data[index].id)}
                              />
                            ) : (
                              <>
                                <img
                                  className="cursor-pointer h-3 w-3"
                                  src={editImg}
                                  alt="edit"
                                  onClick={() => editRow(index)}
                                />
                                <img
                                  className="cursor-pointer h-3 w-3"
                                  src={deleteImg}
                                  alt="Delete"
                                  onClick={() =>
                                    deleteRow(index, data[index].id)
                                  }
                                />
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                  </TableRow>
                ))
              : null}
            <TableRow
              id={`hidden-row-${partBy}`}
              className="hidden"
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {partBy !== "customer" && (
                <TableCell
                  className="w-[30%]"
                  sx={{ padding: "4px 8px", textAlign: "left", pl:3 }}
                >
                  <input
                    className="w-full font-normal text-xs bg-white px-2 py-1 text-left"
                    style={{
                      boxSizing: "border-box",
                      padding: "0 2px",
                      paddingLeft: "0px",
                    }}
                    placeholder="Location"
                    value={newPartLocation}
                    onChange={(e) => setNewPartLocation(e.target.value)}
                  />
                </TableCell>
              )}
              <TableCell
                className="w-[25%]"
                sx={{ padding: "4px 8px", textAlign: "center" }}
              >
                <input
                  className="w-full font-normal text-xs bg-white px-2 py-1 text-center"
                  style={{
                    boxSizing: "border-box",
                    padding: "0 2px",
                    paddingLeft: "2px",
                  }}
                  placeholder="Part"
                  value={newPartName}
                  onChange={(e) => setNewPartName(e.target.value)}
                />
              </TableCell>
              <TableCell
                className="w-[25%]"
                sx={{ padding: "4px 8px", textAlign: "center" }}
              >
                <input
                  className="w-full font-normal text-xs bg-white px-8 py-1 text-center"
                  style={{
                    boxSizing: "border-box",
                    paddingLeft: "0 4px",
                    paddingRight: "0px",
                  }}
                  type="number"
                  value={newPartQuantity}
                  onChange={(e) => {
                    if (e.target.value < 0) return;
                    setNewPartQuantity(e.target.value);
                  }}
                  placeholder="   Qty"
                />
              </TableCell>
              {partBy === "customer" && (
                <TableCell
                  className="w-[30%]"
                  sx={{ padding: "4px 8px", textAlign: "center" }}
                >
                  <div className="relative">
                    <select
                      className="w-full text-center font-normal text-xs bg-white px-2 py-1 appearance-none"
                      value={newPartOrigin}
                      onChange={(e) => setNewPartOrigin(e.target.value)}
                    >
                      {dropdownFromOptions.map((option, idx) => (
                        <option key={idx} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center pointer-events-none">
                      <ArrowDropDownRounded />
                    </div>
                  </div>
                </TableCell>
              )}
              {partBy !== "customer" && (
                <TableCell
                  className="w-[20%]"
                  sx={{ padding: "4px 8px", textAlign: "center" }}
                >
                  <Typography className="text-center text-xs">
                    Pending
                  </Typography>
                </TableCell>
              )}
              <TableCell
                className="w-[10%]"
                sx={{ padding: "4px 8px", textAlign: "center" }}
              >
                <div className="flex justify-center gap-2">
                  <img
                    className="cursor-pointer h-3 w-3"
                    src={saveImg}
                    onClick={addNewPart}
                    alt="save"
                  />
                  <img
                    className="cursor-pointer h-3 w-3"
                    src={deleteImg}
                    alt="Delete"
                    onClick={deleteNewRow}
                  />
                </div>
              </TableCell>
            </TableRow>
            {(status === "new-order" || status === "to-be-assigned") && (
              <TableRow id={`new-row-btn-${partBy}`} sx={{ height: 50 }}>
                <TableCell
                  colSpan={totalColumns}
                  sx={{ textAlign: "center", padding: 0 }}
                >
                  <div className="flex justify-center items-center h-full">
                    <img
                      className="cursor-pointer"
                      onClick={insertRow}
                      src={addWhiteImg}
                      alt="Add"
                      style={{ padding: "8px" }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
