import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { updateToast } from "../helpers";
import { toast } from "react-toastify";

export const loginUser = createAsyncThunk(
  "authSlice/loginUser",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading("Logging in...", { toastId: "login" });
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/login`,
        data,
        {
          // withCredentials: true,
        }
      );
      onSuccess?.(response.data.user);
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "authSlice/logoutUser",
  async ({ onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading("Logging out...", { toastId: "logout" });
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/logout`
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getUser = createAsyncThunk(
  "authSlice/getUser",
  async ({ onFail }, { rejectWithValue }) => {
    try {
      // Create a separate Axios instance without interceptors
      const customAxios = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        withCredentials: true,
      });

      let response = await customAxios.get("/user");
      return response.data;
    } catch (error) {
      console.error(error);
      onFail?.();
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    userLoading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.user = action.payload.user;
      toast.dismiss("login");
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      updateToast(
        action.payload?.message ?? "Error logging in",
        "error",
        "login"
      );
    });

    builder.addCase(logoutUser.fulfilled, (state, action) => {
      updateToast(
        action.payload?.message ?? "Logged out successfully",
        "success",
        "logout"
      );
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      updateToast(
        action.payload?.message ?? "Error logging out",
        "error",
        "logout"
      );
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload?.user;
      state.userLoading = false;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.userLoading = false;
    });
  },
});

//eslint-disable-next-line
export const {} = authSlice.actions;

export default authSlice.reducer;
