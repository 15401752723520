import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { updatePart } from "../../redux-slices/jobsSlice";
import { useDispatch, useSelector } from "react-redux";
import { ArrowDropDownRounded } from "@mui/icons-material";
import { dropdownStatusOptions, formatStatus } from "../../utils/helpers";
import { updateItemStatus } from "../../redux-slices/generalPickupOrderSlice";

export const DriverModalTable = ({
  tab,
  headerTitles,
  data,
  fetchJobData,
  fetchParentData,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const [parts, setParts] = useState([]);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    if (!data) return;
    const formattedData = data.map((item) => ({
      location: item.location,
      part: item.name,
      quantity: item.quantity,
      status: item.status,
    }));
    setParts(formattedData);
  }, [data]);

  const handleChange = (index, field, value) => {
    const newParts = [...parts];
    if (field === "quantity" && value < 0) return;
    newParts[index][field] = value;
    setParts(newParts);
  };

  const editRow = (index) => {
    setEditIndex(index);
  };

  const saveRow = (index, id) => {
    if (parts[index].status === data[index].status) {
      setEditIndex(null);
      return;
    }
    setEditIndex(null);
    if (tab === 0) {
      dispatch(
        updatePart({
          id,
          data: {
            location: parts[index].location,
            name: parts[index].part,
            quantity: parts[index].quantity,
            status: parts[index].status,
          },
          onSuccess: async () => {
            console.log("Job data fetched");
            fetchJobData(); // Only fetch job data here
          },
        })
      );
    } else {
      dispatch(
        updateItemStatus({
          id,
          data: parts[index],
          onSuccess: async () => {
            console.log("Job data fetched");
            fetchJobData(); // Only fetch job data here
          },
        })
      );
    }
  };

  const baseColumns = 4; // Location, Name, Quantity, Status
  const totalColumns =
    user?.roles[0].name === "driver" ? baseColumns + 1 : baseColumns;

  return (
    <div>
      <TableContainer
        className="rounded-lg mt-2 mb-5 shadow-md"
        component={Paper}
      >
        <Table
          sx={{
            tableLayout: "fixed",
            borderCollapse: "collapse",
            width: "100%",
          }}
        >
          <TableHead
            sx={{ backgroundColor: theme.palette.blue.main, width: "100%" }}
          >
            <TableRow>
              {headerTitles.map((header) => (
                <TableCell
                  key={header.id}
                  className={`text-white text-xs ${
                    header.id === 3 ? "w-[30%]" : "" // Location
                  } ${header.id === 0 ? "w-[25%]" : ""} // Name
                  ${header.id === 1 ? "w-[15%]" : ""} // Quantity
                  ${header.id === 2 ? "w-[14%]" : ""}`} // Status
                  sx={{
                    padding: "4px 8px",
                    textAlign: "center",
                  }}
                >
                  {header.title}
                </TableCell>
              ))}
              {user?.roles[0].name === "driver" && (
                <TableCell
                  className="w-[10%] text-xs"
                  sx={{ padding: "4px 8px", textAlign: "center" }}
                ></TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {parts.length > 0
              ? parts.map((part, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      className="w-[20%]"
                      sx={{ padding: "4px 8px", textAlign: "center" }}
                    >
                      <Typography className="text-xs text-center">
                        {part.location || "N/A"}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="w-[25%]"
                      sx={{ padding: "4px 8px", textAlign: "center" }}
                    >
                      <Typography className="text-xs text-center">
                        {part.part}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="w-[15%]"
                      sx={{ padding: "4px 8px", textAlign: "center" }}
                    >
                      <Typography className="text-xs text-center">
                        {part.quantity}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className="w-[40%]"
                      sx={{ padding: "4px 8px", textAlign: "center", px: 0 }}
                    >
                      {editIndex !== index ? (
                        <Typography className="text-xs text-center">
                          {formatStatus(part.status)}
                        </Typography>
                      ) : (
                        <div className="relative flex items-center justify-center">
                          <select
                            className="w-full text-center font-normal text-xs bg-white px-3 py-5 pr-8 appearance-none"
                            value={part.status}
                            onChange={(e) =>
                              handleChange(index, "status", e.target.value)
                            }
                            disabled={editIndex !== index}
                          >
                            {dropdownStatusOptions.map((option, idx) => (
                              <option key={idx} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {editIndex === index && (
                            <div className="absolute inset-y-0 right-1 flex items-center pointer-events-none">
                              <ArrowDropDownRounded />
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                    {user?.roles[0].name === "driver" && (
                      <TableCell
                        className="w-[10%]"
                        sx={{ padding: "4px 8px", textAlign: "center" }}
                      >
                        <div className="flex justify-center">
                          {editIndex === index ? (
                            <img
                              className="cursor-pointer h-3 w-3"
                              src={saveImg}
                              alt="save"
                              onClick={() => saveRow(index, data[index].id)}
                            />
                          ) : (
                            <img
                              className="cursor-pointer h-3 w-3"
                              src={editImg}
                              alt="edit"
                              onClick={() => editRow(index)}
                            />
                          )}
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
