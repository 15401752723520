import React, { useRef, useState, useEffect } from "react";
import CarfixInput from "./CarfixInput";

const LicenseInput = ({
  register = () => {},
  errors = {},
  licensePlate,
  setLicense,
}) => {
  const charRef = useRef(null);
  const firstNumbersRef = useRef(null);
  const secondNumbersRef = useRef(null);
  const [inputs, setInputs] = useState({
    char: "",
    firstNumbers: "",
    secondNumbers: "",
  });

  useEffect(() => {
    if (!licensePlate) return;
    const regex = /^([A-Z\s]+)(?:\s(\d+)-(\d+))?$/i;

    const match = licensePlate.match(regex);

    if (match) {
      setInputs({
        char: match[1]?.trim() || "",
        firstNumbers: match[2] || "",
        secondNumbers: match[3] || "",
      });
    }
  }, [licensePlate]);

  const handleInputChange = (e, nextRef, fieldName, maxLength) => {
    let value = e.target.value;

    // Convert input to uppercase if it's a letter
    if (fieldName === "char") {
      value = value
        .replace(/[^A-Za-z]/g, "")
        .toUpperCase()
        .slice(0, maxLength);
    } else {
      value = value.replace(/[^0-9]/g, "").slice(0, maxLength);
    }

    setInputs((prev) => {
      const newInputs = { ...prev, [fieldName]: value };

      return newInputs;
    });

    // Focus the next input if the current field is filled
    if (value.length === maxLength && nextRef) {
      nextRef.current.focus();
    }
  };

  // Update license after input changes
  useEffect(() => {
    const { char, firstNumbers, secondNumbers } = inputs;

    const bothNumbersSet = firstNumbers && secondNumbers;
    const bothNumbersUnset = !firstNumbers && !secondNumbers;

    if ((char && bothNumbersUnset) || (char && bothNumbersSet)) {
      setLicense(`${char} ${firstNumbers}-${secondNumbers}`);
    } else {
      setLicense("");
    }
  }, [inputs, setLicense]);

  return (
    <div>
      <div className="flex gap-1 items-center">
        {/* Character Input */}
        <CarfixInput
          {...register("license_char")}
          inputRef={charRef}
          className="text-center border border-gray-300 rounded"
          maxLength={5}
          placeholder="Alphabets"
          value={inputs.char}
          error={errors?.char}
          helperText={errors?.char?.message}
          onChange={(e) => handleInputChange(e, firstNumbersRef, "char", 5)}
          style={{ width: 40 + (inputs.char?.length ?? "") * 9 }}
        />
        {/* First Number Group Input */}
        <CarfixInput
          {...register("license_num_1")}
          inputRef={firstNumbersRef}
          className="w-12 text-center border border-gray-300 rounded"
          maxLength={2}
          placeholder="12"
          value={inputs.firstNumbers}
          onChange={(e) =>
            handleInputChange(e, secondNumbersRef, "firstNumbers", 2)
          }
        />
        -{/* Second Number Group Input */}
        <CarfixInput
          {...register("license_num_2")}
          inputRef={secondNumbersRef}
          className="w-12 text-center border border-gray-300 rounded"
          maxLength={2}
          placeholder="34"
          value={inputs.secondNumbers}
          onChange={(e) => handleInputChange(e, null, "secondNumbers", 2)}
        />
      </div>
    </div>
  );
};

export default LicenseInput;
