import React, { useState } from "react";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import ConfirmDialog from "../commonComponents/ConfirmDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  assignJobToDriver,
  assignJobToSupervisor,
  cancelJob,
  cancelPartsOrder,
  updateJobData,
  markJobAsComplete
} from "../../redux-slices/jobsSlice";
import { closeJobModal } from "../../redux-slices/modalSlice";
import { downloadInvoice } from "../../redux-slices/invoiceSlice";
import { resetOrderData } from "../../redux-slices/editOrderDataSlice";
import { useNavigate } from "react-router";

const ReceptionistButtons = ({
  tab,
  remarks,
  submitRemarks,
  editOrderData,
  setEditOrderData,
  fetchData,
  fetchJobData,
  setLoading,
  setOrderEdit,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { hasUpdatedData } = useSelector((state) => state.editOrderData);
  const job = useSelector((state) => state.jobs.job);

  const id = job?.id;
  const status = job?.status;
  const escalated = job?.escalated;

  const [text, setText] = useState("");
  const [boldText, setBoldText] = useState("");
  const [openConfirmDialog, setopenConfirmDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleCloseDontAgree = () => {
    setopenConfirmDialog(false);
  };

  const handleConfirm = () => {
    handleCloseDontAgree();
    switch (selectedId) {
      case 0:
        saveData();
        dispatch(
          assignJobToDriver({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 1:
        saveData();
        dispatch(
          assignJobToSupervisor({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 2:
        saveData();
        dispatch(
          cancelJob({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 3:
        saveData();
        dispatch(
          cancelPartsOrder({
            id,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      case 7:
        saveData();
        dispatch(
          markJobAsComplete({
            invoice_id : job?.invoice_number,
            onSuccess: () => {
              fetchData();
              dispatch(closeJobModal());
            },
          })
        );
        break;
      default:
        break;
    }
  };

  const receptionistButtons = [
    {
      id: 4,
      title: "View Logs",
      show: true,
    },
    {
      id: 5,
      title: "Invoice",
      show: status === "to-be-invoiced",
    },
    {
      id: 0,
      title: "To Driver",
      show: status === "new-order" || status === "to-be-assigned" || status === "to-be-invoiced"
    },
    {
      id: 1,
      title: "To Garage",
      show: status === "new-order" || status === "to-be-assigned" || status === "to-be-invoiced"
    },
    {
      id: 2,
      title: "Cancel Order",
      color: "red",
      show: status === 'new-order' || status === "to-be-assigned",
    },
    {
      id: 3,
      title: "Cancel Purchase Request",
      color: "red",
      show: status === "awaiting-parts" && !escalated,
    },
    {
      id: 6,
      title: "Download Invoice",
      show: status === "invoiced" || status === "completed"
    },
    {
      id: 7,
      title: "Mark as Complete",
      show: status === "invoiced"
    }
  ];

  const editOrderButtons = [
    {
      id: 0,
      title: "Save Changes",
      color: "#f3a366" //Orange
    },
    {
      id: 1,
      title: "Cancel Changes",
      color: "#fb7d7d" //Red
    }
  ]

  const handleEditOrderButtons = (item) => {
    switch(item.id){
      case 0:
        saveData();
        setLoading(true);
        setEditOrderData(false);
        break;
      case 1:
        setLoading(true);
        dispatch(resetOrderData());
        setEditOrderData(false);
        setOrderEdit(val => !val);
        break;
      default:
        break;
    }
  }

  const handleClickOpen = (item) => {
    switch (item.id) {
      case 0:
        setSelectedId(item.id);
        setText("");
        setBoldText(item.title);
        break;
      case 1:
        setSelectedId(item.id);
        setText("");
        setBoldText(item.title);
        break;
      case 2:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        break;
      case 3:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        break;
      case 4:
        saveData();
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/logs/${id}`, {
          state: { job_id: id },
        });
        break;
      case 5:
        saveData();
        dispatch(closeJobModal());
        navigate(`/${user?.roles?.[0]?.name}/invoice/${id}`);
        break;
      case 6:
        saveData();
        dispatch(downloadInvoice({
          invoice_id : job?.invoice_number,
          onSuccess : ()=>{
              fetchData();
              dispatch(closeJobModal());
            }
          })
        );
        break;
      case 7:
        setSelectedId(item.id);
        setText("Are you sure you want to");
        setBoldText(item.title);
        break;
      default:
        break;
    }
    if(item.id !== 6){
      setopenConfirmDialog(true);
    }
  };


  const saveData = () => {
    if (editOrderData) {
      setEditOrderData(false);
      if (hasUpdatedData) {
        dispatch(updateJobData({
          onSuccess: () => fetchJobData(setLoading)
        }));
      }
    }
    if (remarks) {
      submitRemarks();
    }
  };

  return (
    <>
      { editOrderData ? (
        editOrderButtons.map((item) => (
          <Button
            key={item.id}
            className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base md:w-44`}
            style={{
              backgroundColor: item.color,
              textTransform: "none"
            }}
            onClick={() => handleEditOrderButtons(item)}
          >
            {item.title}
          </Button>
        ))
      ) : 
      (receptionistButtons.map((item) => (
        <Button
          key={item.id}
          className={`h-10 text-white mt-7 md:mr-5 rounded-lg text-base ${
            item.show ? "block" : "hidden"
          }
          ${item.id === 3 || item.id === 6 || item.id === 7 ? "md:w-56" : "md:w-36"}
          `}
          style={{
            background:
              item.color === "red"
                ? theme.palette.red.main
                : theme.palette.orange.main,
            textTransform: "none",
          }}
          onClick={() => handleClickOpen(item)}
        >
          {item.title}
        </Button>
      )))}
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseDontAgree}
        onConfirm={handleConfirm}
        text={text}
        boldText={boldText}
      />
    </>
  );
};

export default ReceptionistButtons;
