import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "./authSlice";
import { closeJobModal } from "./modalSlice";

const initialState = {
  updatedData: {},
  hasUpdatedData: false,
};

const editOrderDataSlice = createSlice({
  name: "editOrderData",
  initialState,
  reducers: {
    updateOrderData: (state, action) => {
      const { job_id, index, key, value } = action.payload;
      if (!state.updatedData["job_id"]) {
        state.updatedData["job_id"] = job_id;
      }

      if (state.updatedData["job_id"] !== job_id) {
        state.updatedData = {};
        state.updatedData["job_id"] = job_id;
      }

      if (!state.updatedData[index]) {
        state.updatedData[index] = {};
      }
      state.updatedData[index][key] = value;
      state.hasUpdatedData = true;
    },

    resetOrderData: (state) => {
      state.updatedData = {};
      state.hasUpdatedData = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, () => initialState);

    builder.addCase(closeJobModal.fulfilled, (state) => {
      state.updatedData = {};
      state.hasUpdatedData = false;
    });
  },
});

export const { updateOrderData, resetOrderData } = editOrderDataSlice.actions;

export default editOrderDataSlice.reducer;
