import React from "react";
import { MainHeader } from "../../components/commonComponents/MainHeader";

export const StaffLayout = ({ children, setTab, defaultTab }) => {
  return (
    <div>
      <MainHeader setTab={setTab} defaultTab={defaultTab} />
      <main>{children}</main>
    </div>
  );
};
