import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CursorOrderImg from "../../assets/images/close.svg";
import { OrderData } from "../../components/modalComponents/OrderData";
import { HeadingwithData } from "../../components/modalComponents/HeadingwithData";
import { OrderRemarks } from "../../components/modalComponents/OrderRemarks";
import { MediaWithHeading } from "../../components/modalComponents/MediaWithHeading";
import ShowMedia from "../../components/modalComponents/ShowMedia";
import UploadMedia from "../modalComponents/UploadMedia";
import { AdminModalTable } from "./AdminModalTable";
import AdminButtons from "./AdminModalButtons";
import { useDispatch, useSelector } from "react-redux";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPdf } from "../../redux-slices/pdfSlice";
import { AddRemarks } from "../modalComponents/AddRemarks";
import { handleMediaSubmit, handleRemarksSubmit } from "../../utils/helpers";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { updateJobData } from "../../redux-slices/jobsSlice";
import { closeJobModal } from "../../redux-slices/modalSlice";

const AdminModalDetails = ({
  jobModalOpen,
  closeModal,
  loading,
  setLoading,
  job,
  fetchData,
  fetchJobData,
  setOrderEdit,
  id,
}) => {
  const headerTitles = [
    {
      id: 3,
      title: "Location"
    },
    {
      id: 0,
      title: "Name",
    },
    {
      id: 1,
      title: "Quantity",
    },
    {
      id: 4,
      title: "From",
    },
    {
      id: 2,
      title: "Status",
    },
  ];

  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { hasUpdatedData, updatedData } = useSelector(
    (state) => state.editOrderData
  );

  const [media, setMedia] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [editOrderData, setEditOrderData] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    setEditOrderData(false);
  }, [job]);

  const handlePrint = () => {
    dispatch(getPdf(id));
  };

  const submitMedia = () => {
    handleMediaSubmit(
      media,
      id,
      setMedia,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  const submitRemarks = () => {
    handleRemarksSubmit(
      remarks,
      id,
      setRemarks,
      fetchJobData,
      setLoading,
      setUploadLoading,
      dispatch
    );
  };

  useEffect(() => {
    if (media.length > 0) {
      submitMedia();
    }
    // eslint-disable-next-line
  }, [media]);

  const saveOrderData = () => {
    if (!updatedData?.vehicle?.license_number) {
      alert("License Number is Empty or Invalid");
      return;
    }
    setEditOrderData(false);
    if (hasUpdatedData) {
      dispatch(
        updateJobData({
          onSuccess: () => {
            dispatch(closeJobModal());
            fetchData();
          },
        })
      );
    }
  };

  return (
    <Dialog
      open={jobModalOpen}
      onClose={closeModal}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 30,
          padding: 10,
        },
      }}
    >
      <DialogTitle>
        <div className="flex justify-between">
          <div className="flex">
            <Typography
              className="text-xl md:text-3xl font-bold"
              style={{ color: theme.palette.blue.main }}
            >
              {loading ? "Service Order" : `Service Order ${job?.display_id}`}
            </Typography>
            <div className="flex justify-center items-center">
              <img
                src={editImg}
                alt="Edit data"
                className={`ml-5 h-6 cursor-pointer ${
                  editOrderData ? "hidden" : ""
                }`}
                onClick={() => setEditOrderData(true)}
              />
              <img
                src={PrintImg}
                alt="PrintImg"
                className="ml-5 cursor-pointer"
                onClick={handlePrint}
              />
            </div>
          </div>
          <img
            src={CursorOrderImg}
            alt="CursorOrderImg"
            className="cursor-pointer"
            onClick={closeModal}
          />
        </div>
      </DialogTitle>

      <DialogContent className="mr-7 pl-7">
        {loading ? (
          <div className="h-60 w-96">
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        ) : (
          <div className="orderModal justify-between">
            <div className="flex flex-col w-full 2xl:w-1/2">
              <OrderData
                editOrderData={editOrderData}
                showUserData
                fetchJobData={fetchJobData}
                data={job}
                remarks={remarks}
                submitRemarks={submitRemarks}
                setEditOrderData={setEditOrderData}
              />

              <HeadingwithData
                heading={"Service/Repair Required"}
                data={job?.description}
                job_id={job?.id}
                editOrderData={editOrderData}
              />
              {job?.remarks?.length > 0 && (
                <div>
                  <Typography
                    className="font-semibold text-xl mb-3 mt-4"
                    style={{ color: theme.palette.blue.main }}
                  >
                    Remarks
                  </Typography>
                  <OrderRemarks
                    remarks={job?.remarks}
                    fetchData={fetchJobData}
                  />
                </div>
              )}
              <div className={job?.remarks?.length === 0 ? "mt-5" : ""}>
                <AddRemarks
                  remarks={remarks}
                  setRemarks={setRemarks}
                  submitRemarks={submitRemarks}
                />
              </div>
            </div>
            <div className="flex flex-col w-full 2xl:w-1/2">
              {(job?.status === "new-order" ||
                job?.status === "to-be-assigned" ||
                job?.status === "awaiting-mechanic" ||
                job?.status === "awaiting-review" ||
                job?.status === "to-be-invoiced" ||
                job?.parts_by_customer?.length > 0) && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Parts Available (User / Garage)
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_customer}
                    fetchJobData={fetchJobData}
                    job_id={job?.id}
                    status={job?.status}
                    user_id={job?.customer?.user_id}
                    partBy={"customer"}
                  />
                </>
              )}
              {(job?.status === "new-order" ||
                job?.status === "to-be-assigned" ||
                job?.status === "awaiting-mechanic" ||
                job?.status === "awaiting-review" ||
                job?.status === "to-be-invoiced" ||
                job?.parts_by_garage?.length > 0) && (
                <>
                  <Typography
                    className="text-sm"
                    style={{ color: theme.palette.orange.main }}
                  >
                    Pick up Requests
                  </Typography>
                  <AdminModalTable
                    headerTitles={headerTitles}
                    data={job?.parts_by_garage}
                    fetchJobData={fetchJobData}
                    job_id={job?.id}
                    status={job?.status}
                    user_id={user.id}
                    partBy={"garage"}
                  />
                </>
              )}
              {job?.media_by_customer?.length > 0 && (
                <div className="mb-5 mt-5">
                  <MediaWithHeading
                    heading={"Client"}
                    source={job?.media_by_customer}
                  />
                </div>
              )}
              <div className="mt-5">
                <ShowMedia
                  userRole={user?.roles?.[0]?.name}
                  media={job?.media_by_garage}
                  fetchJobData={fetchData}
                />
              </div>
              {(job?.status !== "invoiced" && job?.status !== "completed" && job?.status !== "cancelled") && (
                <div>
                  <UploadMedia media={media} setMedia={setMedia} />
                </div>
              )}
            </div>
          </div>
        )}
        {uploadLoading && (
          <div>
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <AdminButtons
          remarks={remarks}
          submitRemarks={submitRemarks}
          editOrderData={editOrderData}
          setEditOrderData={setEditOrderData}
          fetchData={fetchData}
          fetchJobData={fetchJobData}
          setOrderEdit={setOrderEdit}
          setLoading={setLoading}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AdminModalDetails;
