import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { logoutUser } from "./authSlice";

// Thunk to fetch notifications
export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (_, { getState, rejectWithValue }) => {
    const { limit } = getState().notifications;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-notifications?limit=${limit}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async ({ limit, offset }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-all-notifications?limit=${limit}&offset=${offset}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const markAsRead = createAsyncThunk(
  "notifications/markAsRead",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/mark-all-as-read`
      );
      dispatch(notificationSlice.actions._markAsRead());
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

const initialState = {
  notifications: [],
  limit: 8,
  totalCount: 0,
  unReadCount: 0,
  loading: false,
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    increaseLimit: (state) => {
      state.limit += 8;
    },
    resetLimit: (state) => {
      state.limit = 8;
    },

    _markAsRead: (state) => {
      state.unReadCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications;
      state.totalCount = action.payload.totalNotificationCount;
      state.unReadCount = action.payload.unreadNotificationCount;
      state.loading = false;
    });
    builder.addCase(getNotifications.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(logoutUser.fulfilled, () => initialState);
  },
});

//eslint-disable-next-line
export const { increaseLimit, resetLimit } = notificationSlice.actions;

export default notificationSlice.reducer;
