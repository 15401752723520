import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../utils/error-page";
import clientRoutes from "./client.routes";
import ProtectedRoute from "./protectedRoutes";
import { Login } from "../pages/Login";
import { Reception } from "../pages/reception/Reception";
import { Supervisor } from "../pages/supervisor/Supervisor";
import { Mechanic } from "../pages/mechanic/Mechanic";
import { Driver } from "../pages/driver/Driver";
import { Admin } from "../pages/admin/Admin";
import Settings from "../pages/admin/Settings";
import Logs from "../pages/Logs";
import Notifications from "../pages/Notifications";
import Invoice from "../pages/commonPages/Invoice";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
    public: true,
  },
  {
    path: "/receptionist",
    element: (
      <ProtectedRoute allowedRoles={["receptionist"]}>
        <Reception />
      </ProtectedRoute>
    ),
  },
  {
    path: "/supervisor",
    element: (
      <ProtectedRoute allowedRoles={["supervisor"]}>
        <Supervisor />
      </ProtectedRoute>
    ),
  },
  {
    path: "/mechanic",
    element: (
      <ProtectedRoute allowedRoles={["mechanic"]}>
        <Mechanic />
      </ProtectedRoute>
    ),
  },
  {
    path: "/driver",
    element: (
      <ProtectedRoute allowedRoles={["driver"]}>
        <Driver />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin",
    element: (
      <ProtectedRoute allowedRoles={["admin"]}>
        <Admin />
      </ProtectedRoute>
    ),
  },
  {
    path: "/admin/settings",
    element: (
      <ProtectedRoute allowedRoles={["admin"]}>
        <Settings />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:role/logs/:job_id",
    element: (
      <ProtectedRoute
        allowedRoles={[
          "admin",
          "receptionist",
          "supervisor",
          "mechanic",
          "driver",
        ]}
      >
        <Logs />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:role/invoice/:job_id",
    element: (
      <ProtectedRoute allowedRoles={["admin", "receptionist", "supervisor"]}>
        <Invoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:role/invoice/edit/:job_id",
    element: (
      <ProtectedRoute allowedRoles={["admin", "receptionist", "supervisor"]}>
        <Invoice />
      </ProtectedRoute>
    ),
  },
  {
    path: "/:role/notifications",
    element: (
      <ProtectedRoute
        allowedRoles={["admin", "receptionist", "supervisor", "mechanic"]}
      >
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    path: "/unauthorized",
    element: <ErrorPage title="Unauthorized" />,
  },
  ...clientRoutes,
]);

export default router;
