import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export default styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: 13,
    borderRadius: 10,
    backgroundColor: "#FAFDFF",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
  },
  "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.orange.main,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderRadius: "10px",
    borderColor: theme.palette.orange.main,
  },
}));
