import {
  Badge,
  CircularProgress,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import notificationIcon from "../../assets/images/notificationOrange.svg";
import notificationWhiteIcon from "../../assets/images/notificationWhite.svg";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import useNotifications from "../../hooks/useNotifications";
import { useNavigate } from "react-router";
import { openJobModal } from "../../redux-slices/modalSlice";

export const NotificationComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { limit, totalCount, unReadCount, loading } = useSelector(
    (state) => state.notifications
  );

  const {
    notifications,
    resetNotificationLimit,
    increaseNotificationLimit,
    markAllAsRead,
  } = useNotifications();

  const [showNotification, setShowNotification] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openJobDetailModal = (jobId) => () => {
    dispatch(openJobModal({ id: jobId }));
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShowNotification(true);
    markAllAsRead();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowNotification(false);
  };

  return (
    <div>
      <Tooltip title="Notifications">
        <Badge badgeContent={unReadCount} color="error">
          <img
            src={notificationWhiteIcon}
            className="hidden sm:block cursor-pointer"
            onClick={handleOpen}
            alt="Notification"
          />
          <img
            src={notificationIcon}
            className="sm:hidden cursor-pointer"
            onClick={handleOpen}
            alt="Notification"
          />
        </Badge>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={showNotification}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          marginTop: "5px",
          marginRight: "15px",
        }}
      >
        <div className="md:w-96" style={{ width: "260px" }}>
          <div className="flex justify-between items-center px-4 py-2 border-b border-gray-200">
            <Typography
              className="font-bold text-lg"
              style={{ color: theme.palette.blue.main }}
            >
              Notifications
            </Typography>
            {!loading && notifications?.length > 0 && (
              <Typography
                className="font-bold cursor-pointer"
                onClick={() => {
                  resetNotificationLimit();
                }}
                style={{ color: theme.palette.orange.main }}
              >
                Refresh
              </Typography>
            )}
          </div>

          {loading ? (
            <div className="flex justify-center p-10">
              <CircularProgress
                sx={{ color: theme.palette.orange.main }}
                size={20}
              />
            </div>
          ) : (
            notifications?.map((notification) => (
              <div
                className={`flex flex-col items-start justify-center border-b border-gray-200 p-2 cursor-pointer 
                ${!notification.is_read ? "bg-orange-50" : ""}`}
                onClick={openJobDetailModal(notification.job_id)}
                key={notification.id}
              >
                <div className="flex justify-between items-center w-full">
                  <Typography className="font-bold">
                    {notification.title}
                  </Typography>
                  <Typography className="text-xs text-gray-400">
                    {new Date(notification.created_at).toLocaleString()}
                  </Typography>
                </div>
                <div className="flex flex-col items-start pl-1 mt-1">
                  <Typography
                    style={{
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                      width: "100%",
                    }}
                  >
                    {notification.message}
                  </Typography>
                </div>
              </div>
            ))
          )}
          {!loading && limit < totalCount && (
            <div className="flex justify-between items-center px-4 pt-3 pb-1">
              <Typography
                className="font-bold cursor-pointer"
                onClick={() => increaseNotificationLimit()}
                style={{ color: theme.palette.orange.main }}
              >
                Load More
              </Typography>
              <Typography
                className="font-bold cursor-pointer"
                onClick={() =>
                  navigate(`/${user?.roles?.[0]?.name}/notifications`)
                }
                style={{ color: theme.palette.orange.main }}
              >
                View All {">>"}
              </Typography>
            </div>
          )}
          {notifications?.length === 0 && !loading && (
            <div className="flex justify-center p-10">
              <Typography className="text-lg">No Notifications</Typography>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
};
