import React, { useEffect, useState } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import {
  Button,
  CircularProgress,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  capitalize,
  tableCellClasses,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import backImg from "../../assets/images/backArrow.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import CarfixInput from "../../components/commonComponents/CarfixInput";
import InvoicingTable from "../../components/commonComponents/InvoicingTable";
import { useParams } from "react-router-dom";
import { getJob } from "../../redux-slices/jobsSlice";
import {
  fetchServices,
  fetchParts,
  generateInvoice,
  editInvoice,
} from "../../redux-slices/invoiceSlice";
import { toast } from "react-toastify";
import { formatNumericInputWithComma } from "../../utils/helpers";

const Invoice = () => {
  const { job_id } = useParams();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { job } = useSelector((state) => state.jobs);
  const {
    services,
    parts,
    status: invoiceStatus,
  } = useSelector((state) => state.invoice);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const displayJobid = job?.display_id;
  const [partsData, setPartsData] = useState([]);
  const [servicesDone, setServicesDone] = useState([]);
  const [partsTotal, setPartsTotal] = useState(0);
  const [servicesTotal, setServicesTotal] = useState(0);
  const [totalWithTax, setTotalWithTax] = useState(0);
  const [loading, setLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [nextService, setNextService] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [discountType, setDiscountType] = useState("amount")
  const [discount, setDiscount] = useState(job?.invoice_discount ?? 0);
  const [discountedValue, setDiscountedValue] = useState(0);
  const roleRedirects = {
    admin: "/admin",
    supervisor: "/supervisor",
  };

  const getRedirectUrl = () => {
    const role = user?.roles?.[0]?.name;
    return roleRedirects[role] || "/";
  };

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(fetchParts());
  }, [dispatch]);

  useEffect(() => {
    const sumWithTax =
      partsTotal + servicesTotal + (partsTotal + servicesTotal) * 0.06;
    setTotalAmount(sumWithTax.toFixed(2));
  }, [partsTotal, servicesTotal]);

  useEffect(() => {
    if (!job) {
      dispatch(getJob(job_id));
    } else {
      const partsByGarage = JSON.parse(
        JSON.stringify(job.parts_by_garage || [])
      );
      let partsByCustomer = JSON.parse(
        JSON.stringify(job.parts_by_customer || [])
      );
      partsByCustomer = partsByCustomer.filter((part) => part.from !== "user");
      const partsCopy = [...partsByGarage, ...partsByCustomer].map((part) => {
        const quantity = parseFloat(part.quantity) || 0;
        const unitPrice = parseFloat(part.unit_price) || 0;
        const amount = parseFloat((quantity * unitPrice).toFixed(2)) || 0;
        return { ...part, quantity, unit_price: unitPrice, amount };
      });

      const getServices = JSON.parse(JSON.stringify(job.get_services || []));
      const servicesDone = JSON.parse(JSON.stringify(job.services_done || []));
      const servicesCopy = [...getServices, ...servicesDone].map((service) => {
        const quantity = parseFloat(service.quantity) || 0;
        const unitPrice = parseFloat(service.unit_price) || 0;
        const amount = parseFloat((quantity * unitPrice).toFixed(2)) || 0;
        return { ...service, quantity, unit_price: unitPrice, amount };
      });

      setNextService(job?.vehicle?.next_service ?? "");
      setPartsData(partsCopy);
      setServicesDone(servicesCopy);
      setLoading(false);

      const partsSum = partsCopy.reduce(
        (sum, p) => sum + (parseFloat(p.amount) || 0),
        0
      );
      const servicesSum = servicesCopy.reduce(
        (sum, s) => sum + (parseFloat(s.amount) || 0),
        0
      );
      setPartsTotal(parseFloat(partsSum.toFixed(2)));
      setServicesTotal(parseFloat(servicesSum.toFixed(2)));
    }
  }, [job_id, job, dispatch]);

  useEffect(() => {
    const partsSum = partsData.reduce(
      (sum, p) => sum + (parseFloat(p.amount) || 0),
      0
    );
    const servicesSum = servicesDone.reduce(
      (sum, s) => sum + (parseFloat(s.amount) || 0),
      0
    );
    setPartsTotal(parseFloat(partsSum.toFixed(2)));
    setServicesTotal(parseFloat(servicesSum.toFixed(2)));
  }, [partsData, servicesDone]);

  useEffect(() => {
    var sumWithoutTax = 0;
    var sumWithTax = 0;
    let discountAmount = 0;
    
    sumWithoutTax = partsTotal + servicesTotal;

    if (discountType === "percentage") {
      if(discount > 100){
        toast.error("Maximum Discount Limit Reached")
        setDiscount(0)
        return
      }
      discountAmount = sumWithoutTax * (discount / 100);
    } else if (discountType === "amount") {
      if(discount > sumWithoutTax && sumWithoutTax > 0){
        toast.error("Maximum Discount Limit Reached")
        setDiscount(0)
        return
      }
      discountAmount = Number(discount);
    }

    sumWithoutTax -= discountAmount;

    sumWithTax += sumWithoutTax + ((sumWithoutTax) * 0.06);
    setTotalWithTax(sumWithTax.toFixed(2));
  }, [partsTotal, servicesTotal, discount, discountType]);

  useEffect(() => {
    if (invoiceStatus === "failed") {
      toast.error("Failed to load services or parts");
    }
  }, [invoiceStatus]);

  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    let subtotal = partsTotal + servicesTotal;
      // eslint-disable-next-line default-case
      switch(discountType){
        case "percentage":
          setDiscountedValue(subtotal * (parseFloat(discount) / 100));
          break;
        case "amount":
          setDiscountedValue(discount);
          break;
      }
  }, [discount, discountType, partsTotal, servicesTotal]);

  const onSubmit = async (data) => {
    if (!servicesDone.length && !partsData.length) {
      return toast.error("Please add any Parts or Services!");
    }

    for (let i = 0; i < servicesDone.length; i++) {
      let service = servicesDone[i];
      if (
        !service.code
      )
        return toast.error(
          "Services cannot be empty"
        );
    }

    for (let i = 0; i < partsData.length; i++) {
      let part = partsData[i];
      if (
        !part.unit_price ||
        !part.quantity ||
        !part.name ||
        !part.cost_price ||
        // eslint-disable-next-line eqeqeq
        part.unit_price == 0 ||
        // eslint-disable-next-line eqeqeq
        part.quantity == 0
      )
        return toast.error(
          "Please Fill all fields in Parts Table"
        );
    }

    data.parts = partsData;
    data.services = servicesDone.filter(
      (service) => service && (service.id || service.code)
    );

    const partsTotalCalc = partsData.reduce(
      (sum, p) => sum + (p.quantity * p.unit_price || 0),
      0
    );
    const servicesTotalCalc = servicesDone.reduce(
      (sum, s) => sum + (s.quantity * s.unit_price || 0),
      0
    );
    let subtotal = partsTotalCalc + servicesTotalCalc;
    let discountAmount = 0;
    if (discountType === "percentage") {
      discountAmount = subtotal * (discount / 100);
    } else if (discountType === "amount") {
      discountAmount = Number(discount);
    }
    const calculatedTotal = (subtotal * 1.06).toFixed(2);

    const formattedData = {
      id: job_id,
      ...data,
      date: selectedDate,
      next_service: data.next_service.includes(",")
        ? data.next_service.replace(/,/g, "")
        : data.next_service,
      total: totalWithTax,
      discount: discountAmount, 
    };

    console.log("Final payload:", JSON.stringify(formattedData, null, 2));

    if (job.invoice_number) {
      dispatch(
        editInvoice({
          id: job.invoice_number,
          data: formattedData,
          onSuccess: () => navigate(`/admin`),
        })
      );
    } else {
      console.log("Dispatching generateInvoice with:", formattedData);
      dispatch(generateInvoice({
          data: formattedData,
          onSuccess: () => navigate(`/${user.roles[0].name}`)
        }))
        .unwrap()
        .then(() => navigate(getRedirectUrl()))
        .catch((error) => console.error("Generation failed:", error));
    }
  };

  const attributes = [
    { label: "Customer Name", value: job?.customer?.user?.name },
    { label: "Make and Model", value: job?.vehicle?.make_and_model },
    { label: "License Plate#", value: job?.vehicle?.license_number },
    { label: "Vin/Chassis #", value: job?.vehicle?.chassis_number },
    {
      label: "Current Mileage",
      value:
        formatNumericInputWithComma(job?.vehicle?.mileage) +
        " " +
        job?.vehicle?.mileage_unit,
    },
    { label: "Service Required", value: job?.description },
  ];

  return (
    (document.title =
      "Carfix Curacao | " + (user ? capitalize(user.roles[0].name) : "")),
    (
      <StaffLayout>
        <div className="mx-7 md:mx-10 pb-5">
          <div className="flex items-center py-10">
            <div className="flex justify-center" onClick={handleGoBack}>
              <img className="cursor-pointer" src={backImg} alt="back" />
            </div>
            <Typography
              className="ml-4 font-bold text-3xl"
              style={{ color: theme.palette.blue.main }}
            >
              {displayJobid ?? job_id} Invoice
            </Typography>
          </div>

          {loading || invoiceStatus === "loading" ? (
            <CircularProgress
              sx={{ color: theme.palette.orange.main }}
              className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
            />
          ) : (
            <form
              className="lg:px-20 xl:px-52"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="flex flex-col sm:flex-row">
                <div className="w-full flex justify-center">
                  <TableContainer elevation={0} component={Paper}>
                    <Table
                      sx={{
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: "none",
                        },
                      }}
                    >
                      <TableBody>
                        {attributes?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="left"
                              sx={{ padding: "8px 16px", height: "30px" }}
                            >
                              <Typography
                                className="font-bold text-sm"
                                style={{ color: theme.palette.text.darkBlue }}
                              >
                                {item.label}:
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ padding: "8px 16px" }}>
                              <Typography
                                className="text-sm"
                                style={{ color: theme.palette.text.darkBlue }}
                              >
                                {item.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="w-full pt-4 px-4 sm:px-0">
                  <Typography
                    className="mb-2 font-bold text-sm"
                    style={{
                      color: theme.palette.text.darkBlue,
                    }}
                  >
                    Date
                  </Typography>
                  <CarfixInput
                    {...register("date", { required: "Date is required" })}
                    className="lg:w-1/2 w-full"
                    type="date"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                    error={Boolean(errors?.date)}
                    helperText={errors?.date?.message}
                  />
                  <Typography
                    className="mt-4 mb-2 font-bold text-sm"
                    style={{
                      color: theme.palette.text.darkBlue,
                    }}
                  >
                    Next Inspection
                  </Typography>
                  <CarfixInput
                    {...register("next_service")}
                    className="lg:w-1/2 w-full"
                    type="text"
                    value={nextService}
                    placeholder="Next Inspection Mileage"
                    onChange={(e) => {
                      const formattedValue = formatNumericInputWithComma(
                        e.target.value
                      );
                      setNextService(formattedValue);
                    }}
                    error={Boolean(errors?.next_service)}
                    helperText={errors?.next_service?.message}
                  />
                </div>
              </div>

              <div className="px-4">
                <InvoicingTable
                  data={servicesDone}
                  setData={setServicesDone}
                  total={servicesTotal}
                  setTotal={setServicesTotal}
                  services={services} // Pass services to InvoicingTable
                />
              </div>
              <div className="px-4">
                <InvoicingTable
                  isPartsTable
                  data={partsData}
                  setData={setPartsData}
                  total={partsTotal}
                  setTotal={setPartsTotal}
                  parts={parts} // Pass parts to InvoicingTable
                />
              </div>
              <div className="flex mt-8 justify-between sm:items-center px-4 flex-col sm:flex-row">
                <div className="sm:w-1/2">
                  <Typography
                    className="mb-2 font-bold text-sm"
                    style={{ color: theme.palette.text.darkBlue }}
                  >
                    Invoicing Remarks
                  </Typography>
                  <CarfixInput
                    {...register("remarks")}
                    className="text-sm w-full"
                    multiline
                    rows={4}
                    placeholder="Enter Remarks for Invoice (if any)"
                    error={Boolean(errors?.description)}
                    helperText={errors?.description?.message}
                  />
                </div>
                <div className="flex flex-col pl-4 md:pl-0 md:w-1/3 mt-4 sm:mt-0">
                <div className="flex justify-between items-start mb-4">
                    <Typography
                      className="font-bold text-sm pt-4"
                      style={{ color: theme.palette.text.darkBlue }}
                    >
                      Discount:
                    </Typography>
                    <div className="flex flex-col w-2/3 space-y-2">
                      <Select native className="w-full rounded-lg bg-white" value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                        <option value="amount">Amount</option>
                        <option value="percentage">Percentage %</option>
                      </Select>
                      <TextField 
                        type="number"
                        placeholder={
                          discountType === "percentage" ? "Enter % discount" : "Enter amount"
                        }
                        value={discount}
                        onChange={(e) => {
                          setDiscount(e.target.value)
                        }}
                        className="w-full rounded-lg bg-white"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <Typography
                      className="font-bold text-sm"
                      style={{ color: theme.palette.text.darkBlue }}
                    >
                      Tax:
                    </Typography>
                    <Select native className="w-2/3 rounded-lg" value={"6"}>
                      <option value="6">6%</option>
                    </Select>
                  </div>
                  <div className="flex justify-between items-center mt-2">
                    <Typography
                      className="font-bold text-xl mt-7"
                      style={{ color: theme.palette.text.darkBlue }}
                    >
                      Total:
                    </Typography>
                    <Typography
                      className="font-normal text-xl mt-7"
                      style={{ color: theme.palette.text.darkBlue }}
                    >
                      {(partsTotal + servicesTotal).toFixed(2)} + 6% {discountedValue > 0 ? `-${(Number(discountedValue) || 0).toFixed(2)}` : ""}={" "}
                      <b>{totalWithTax}</b> ANG
                    </Typography>
                  </div>
                </div>
              </div>

              <div className="flex justify-end mt-10">
                <Button
                  className="h-10 text-white rounded-lg text-base w-44 ml-4"  
                  type="submit"
                  style={{
                    background: theme.palette.orange.main,
                    textTransform: "none",
                  }}
                >
                  Generate Invoice
                </Button>
              </div>
            </form>
          )}
        </div>
      </StaffLayout>
    )
  );
};

export default Invoice;
