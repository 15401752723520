import React, { useEffect, useState, useRef } from "react";
import { StaffLayout } from "../layouts/StaffLayout";
import { Navbar } from "../../components/commonComponents/Navbar";
import { StaffTableAdjusted } from "../../components/commonComponents/StaffTableAdjusted";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../../redux-slices/jobsSlice";
import arrowdown from "../../assets/images/arrowDownWhite.svg";
import sortIcon from "../../assets/images/sort.svg";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@emotion/react";

export const Mechanic = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [limit, setLimit] = useState(20);
  const [tab, setTab] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState([]);
  const { jobs } = useSelector((state) => state.jobs);
  const isFirstRender = useRef(true);

  const fetchData = (search) => {
    setLoading(true);
    dispatch(getJobs({ tab: tabData[tab].val, search, sort })).then(() =>
      setLoading(false)
    );
  };

  useEffect(() => {
    setLimit(20);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if(!isFirstRender.current){
      const handle = setTimeout(() => {
        fetchData(searchValue);
      }, 1000);
  
      return () => {
        clearTimeout(handle);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    if(!isFirstRender.current){
      fetchData(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, limit]);

  useEffect(() => {
    setTimeout(() => {
      isFirstRender.current = false;
    }, 2000);
  }, [])
  

  const updateSorting = (headerId) => {
    const newSort = [...sort];
    const column = headerTitles[headerId].val;
    const index = newSort.indexOf(column);

    if (index === -1) {
      newSort.push(column);
    } else {
      newSort.splice(index, 1);
    }

    setSort(newSort);
  };

  const tabData = [
    { id: 0, val: "neworders", title: "In Progress" },
    { id: 1, val: "reassigned", title: "Reassigned" },
  ];

  const headerTitles = [
    { id: 0, title: "Order ID" },
    { id: 1, title: "Date & Time", img: sortIcon, val: "created_at" },
    { id: 2, title: "Make & Model" },
    { id: 3, title: "License Plate" },
  ];

  return (
    (document.title = "Carfix Curacao | Mechanic"),
    (
      <StaffLayout setTab={setTab} defaultTab={0}>
        <Navbar
          tab={tab}
          setTab={setTab}
          tabData={tabData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        {loading ? (
          <CircularProgress
            sx={{ color: theme.palette.orange.main }}
            className="fixed top-[50%] left-[50%] -translate-x-1/2 -translate-y-1/2"
          />
        ) : (
          <StaffTableAdjusted
            jobs={jobs}
            headerTitles={headerTitles}
            sort={sort}
            tab={tab}
            setLimit={setLimit}
            setSorting={updateSorting}
            fetchData={fetchData}
          />
        )}
      </StaffLayout>
    )
  );
};
