import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { capitalize } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import editImg from "../../assets/images/edit.svg";
import saveImg from "../../assets/images/saveTick.svg";
import { updateRemarks, getDriverJob } from "../../redux-slices/jobsSlice";
import { updateRemarksToGeneralPickupOrders } from "../../redux-slices/generalPickupOrderSlice";

export const OrderRemarks = ({
  remarks,
  fetchData,
  jobId,
  general_pickup_id,
}) => {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [editRemarkId, setEditRemarkId] = useState(null);
  const [editRemarkContent, setEditRemarkContent] = useState("");
  const [error, setError] = useState(null);
  const [jobData, setJobData] = useState(null);

  // Log the received props for debugging
  useEffect(() => {
    console.log("OrderRemarks: Props received:", { jobId, general_pickup_id });
  }, [jobId, general_pickup_id]);

  // Fetch job/pickup order data for informational purposes (not strictly required for update)
  useEffect(() => {
    if (jobId && !jobData) {
      console.log("OrderRemarks: Fetching job data for id:", jobId);
      dispatch(getDriverJob({ id: jobId, tab: general_pickup_id ? 1 : 0 }))
        .unwrap()
        .then((response) => {
          console.log("OrderRemarks: Job data fetched:", response);
          setJobData(response.data);
        })
        .catch((err) => {
          console.warn(
            "OrderRemarks: Failed to fetch job data (proceeding with update anyway):",
            err
          );
        });
    }
  }, [jobId, dispatch, jobData, general_pickup_id]);

  const handleRemarkEditSave = (item) => {
    return () => {
      if (editRemarkId === item?.id) {
        setEditRemarkId(null);
        if (editRemarkContent === item?.content) {
          return; // No changes to save
        }

        if (general_pickup_id) {
          // Update remark for GeneralPickupOrder
          dispatch(
            updateRemarksToGeneralPickupOrders({
              id: item?.id,
              remarks: editRemarkContent,
              general_pickup_id: general_pickup_id, // Include general_pickup_id in the payload
              onSuccess: () => {
                console.log(
                  "OrderRemarks: Remark updated successfully for GeneralPickupOrder"
                );
                fetchData();
                setError(null);
              },
              onError: (err) => {
                const errorMessage =
                  err?.message ||
                  "Failed to update remark. The associated pickup order may not exist.";
                setError(errorMessage);
                console.error(
                  "OrderRemarks: Error updating remark for GeneralPickupOrder:",
                  err
                );
              },
            })
          ).catch((err) => {
            const errorMessage =
              err?.message ||
              "Failed to update remark for GeneralPickupOrder. An unexpected error occurred.";
            setError(errorMessage);
            console.error(
              "OrderRemarks: Unexpected error updating remark for GeneralPickupOrder:",
              err
            );
          });
        } else {
          // Update remark for Service Order (CarJob)
          dispatch(
            updateRemarks({
              id: item?.id,
              remarks: editRemarkContent,
              onSuccess: () => {
                console.log(
                  "OrderRemarks: Remark updated successfully for CarJob"
                );
                fetchData();
                setError(null);
              },
              onError: (err) => {
                const errorMessage =
                  err?.message ||
                  "Failed to update remark. The associated job may not exist.";
                setError(errorMessage);
                console.error(
                  "OrderRemarks: Error updating remark for CarJob:",
                  err
                );
              },
            })
          ).catch((err) => {
            const errorMessage =
              err?.message ||
              "Failed to update remark for CarJob. An unexpected error occurred.";
            setError(errorMessage);
            console.error(
              "OrderRemarks: Unexpected error updating remark for CarJob:",
              err
            );
          });
        }
      } else {
        setEditRemarkContent(item?.content);
        setEditRemarkId(item?.id);
      }
    };
  };

  return (
    <div className="flex flex-col w-11/12 text-justify">
      {error && (
        <Typography className="text-sm text-red-500 mb-2">{error}</Typography>
      )}
      {remarks?.length > 0 ? (
        remarks.map((item, index) => (
          <div key={index} className="flex flex-col mb-3">
            <div className="flex justify-between items-center">
              <Typography
                className="text-xs mb-1 mr-2"
                style={{ color: "#F3A366" }}
              >
                {capitalize(item?.user_name)}'s Remarks
              </Typography>
              {user?.id === item?.user_id && (
                <img
                  src={editRemarkId === item?.id ? saveImg : editImg}
                  alt="remark"
                  className="w-3 h-3 inline-block mx-2 cursor-pointer"
                  onClick={handleRemarkEditSave(item)}
                />
              )}
              <div
                className="flex-grow text-center bg-gray-200"
                style={{ height: 1 }}
              ></div>
              <Typography className="text-xs ml-2 text-gray-400">
                {dayjs(item?.created_at).format("MMM DD, YYYY h:mm A")}
              </Typography>
            </div>

            {user?.id === item?.user_id && editRemarkId === item?.id ? (
              <input
                className="border-gray-200 text-sm border poppins rounded-md p-1"
                value={editRemarkContent}
                onChange={(e) => setEditRemarkContent(e.target.value)}
              />
            ) : (
              <Typography className="text-sm">{item.content}</Typography>
            )}
          </div>
        ))
      ) : (
        <Typography className="text-sm">No remarks available</Typography>
      )}
    </div>
  );
};
