import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "./authSlice";
import { getNotifications } from "./notificationSlice";

export const closeJobModal = createAsyncThunk(
  "modals/closeJobModal",
  async (_, { dispatch }) => {
    dispatch(getNotifications());
    dispatch(modalSlice.actions.hideJobModalActions());
    dispatch(modalSlice.actions._closeJobModal());
  }
);

export const openJobModal = createAsyncThunk(
  "modals/closeJobModal",
  async ({ id }, { dispatch }) => {
    dispatch(getNotifications());
    dispatch(modalSlice.actions._openJobModal(id));
  }
);

const initialState = {
  jobModalOpen: false,
  showJobModalActions: false,
  mechanicSelectModalOpen: false,
  reassignVal: false,
  modalJobId: null,
};

const modalSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    _openJobModal: (state, action) => {
      state.modalJobId = action.payload;
      state.jobModalOpen = true;
    },
    _closeJobModal: (state) => {
      state.jobModalOpen = false;
    },
    showJobModalActions: (state) => {
      state.showJobModalActions = true;
    },
    hideJobModalActions: (state) => {
      state.showJobModalActions = false;
    },
    openMechanicSelectModal: (state) => {
      state.mechanicSelectModalOpen = true;
    },
    closeMechanicSelectModal: (state) => {
      state.mechanicSelectModalOpen = false;
    },

    setReassignTrue: (state) => {
      state.reassignVal = true;
    },
    setReassignFalse: (state) => {
      state.reassignVal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutUser.fulfilled, () => initialState);
  },
});

export const {
  closeInvoiceNumberModal,
  openMechanicSelectModal,
  closeMechanicSelectModal,
  setReassignTrue,
  setReassignFalse,
  showJobModalActions,
  hideJobModalActions,
} = modalSlice.actions;

export default modalSlice.reducer;
