import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { updateToast } from "../helpers";
import { toast } from "react-toastify";
import { resetOrderData } from "./editOrderDataSlice";
import { processFileDownload } from '../utils/helpers';

export const createJob = createAsyncThunk(
  "jobsSlice/createJob",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      toast.loading("Creating job...", { toastId: "creating-job" });
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/create-job`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getJobs = createAsyncThunk(
  "jobsSlice/getJobs",
  async (data, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-jobs`,
        { params: data }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDriverJobs = createAsyncThunk(
  "jobsSlice/getDriverJobs",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-driver-jobs`,
        { params: data }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getJob = createAsyncThunk(
  "jobsSlice/getJob",
  async (id, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-job/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getDriverJob = createAsyncThunk(
  "jobsSlice/getDriverJob",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-driver-job`,
        { params: data }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updateJobData = createAsyncThunk(
  "jobsSlice/updateJobData",
  async (
    { onSuccess = () => {} } = {},
    { getState, dispatch, rejectWithValue }
  ) => {
    const updatedData = getState().editOrderData.updatedData;
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/update-job-data`,
        updatedData
      );
      onSuccess(); // Safely call onSuccess
      dispatch(resetOrderData());
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignJobToSupervisor = createAsyncThunk(
  "jobsSlice/assignJobToSupervisor",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/assign-job-to-supervisor`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignJobToDriver = createAsyncThunk(
  "jobsSlice/assignJobToDriver",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/assign-job-to-driver`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignJobToMechanic = createAsyncThunk(
  "jobsSlice/assignJobToMechanic",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/assign-job-to-mechanic`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const completedByMechanic = createAsyncThunk(
  "jobsSlice/completedByMechanic",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/completed-by-mechanic`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const completedByDriver = createAsyncThunk(
  "jobsSlice/completedByDriver",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/completed-by-driver`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const approveJob = createAsyncThunk(
  "jobsSlice/approveJob",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/approve-job`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const assignToReceptionistForInvoicing = createAsyncThunk(
  "jobsSlice/assignToReceptionistForInvoicing",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/assign-to-receptionist-for-invoicing`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelPartsOrder = createAsyncThunk(
  "jobsSlice/cancelPartsOrder",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-parts-order`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const cancelJob = createAsyncThunk(
  "jobsSlice/cancelJob",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/cancel-job`,
        { id }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const closeJob = createAsyncThunk(
  "jobsSlice/closeJob",
  async ({ id, invoice_number, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/close-job`,
        { id, invoice_number }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const getJobLogs = createAsyncThunk(
  "jobsSlice/getJobLogs",
  async ({ id }, { rejectWithValue }) => {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/get-job-logs`,
        { params: { id } }
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addPart = createAsyncThunk(
  "jobsSlice/addPart",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-part`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const updatePart = createAsyncThunk(
  "jobsSlice/updatePart",
  async ({ id, data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/update-part/${id}`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deletePart = createAsyncThunk(
  "jobsSlice/deletePart",
  async (id, { rejectWithValue }) => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-part/${id}`
      );
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addRemarks = createAsyncThunk(
  "jobsSlice/addRemarks",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-remarks`,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);


export const updateRemarks = createAsyncThunk(
  "jobsSlice/updateRemarks",
  async ({ id, remarks, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/update-remarks/${id}`,
        { remarks }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const addMedia = createAsyncThunk(
  "jobsSlice/addMedia",
  async ({ data, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/add-media`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteMedia = createAsyncThunk(
  "jobsSlice/deleteMedia",
  async ({ id, onSuccess }, { rejectWithValue }) => {
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BASE_URL}/delete-media/${id}`
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const markJobAsComplete = createAsyncThunk(
  "jobsSlice/markJobAsComplete",
  async ({ invoice_id, onSuccess }, { rejectWithValue }) => {
    const loadingToast = toast.loading("Generating invoice...");
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/mark-job-complete/${invoice_id}`, 
        {
          params : {
            sendEmail : true,
          },
          responseType: "blob",
        }
      );

      toast.dismiss(loadingToast);

      const contentType = response.headers.get("content-type");

      if(contentType.includes("application/pdf")){
        const contentDisposition = response.headers.get("Content-Disposition");
        let filename = "Carfix Invoice.pdf";

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match) {
            filename = match[1];
          }
        }

        onSuccess?.();
        return { file: response.data, filename };
      }else{
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onload = () => {
            try {
              const errorData = JSON.parse(reader.result);
              reject(rejectWithValue(errorData));
            } catch (parseError) {
              reject(rejectWithValue({ message: "Unknown error occurred" }));
            }
          };
          reader.readAsText(response.data);
        });
      }
    } catch (error) {
      toast.dismiss(loadingToast);
      console.log(error);
      return rejectWithValue(error?.response?.data);
    }
  }
);

export const jobsSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: null,
    job: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createJob.fulfilled, (state, action) => {
      updateToast(
        action.payload?.message ?? "Job created successfully",
        "success",
        "creating-job"
      );
    });
    builder.addCase(createJob.rejected, (state, action) => {
      updateToast(
        action.payload?.message ?? "Error creating job",
        "error",
        "creating-job"
      );
    });

    builder.addCase(getJobs.fulfilled, (state, action) => {
      state.jobs = action.payload.data;
    });
    builder.addCase(getJobs.rejected, (state, action) => {
      toast.error("Error fetching jobs");
    });

    builder.addCase(getDriverJobs.fulfilled, (state, action) => {
      state.jobs = action.payload.data;
    });
    builder.addCase(getDriverJobs.rejected, (state, action) => {
      toast.error("Error fetching jobs");
    });

    builder.addCase(updateJobData.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job Updated successfully");
    });
    builder.addCase(updateJobData.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error updating Job");
    });

    builder.addCase(getJob.fulfilled, (state, action) => {
      state.job = action.payload.data;
    });
    builder.addCase(getJob.rejected, (state, action) => {
      toast.error("Error fetching job");
    });

    builder.addCase(getDriverJob.fulfilled, (state, action) => {
      state.job = action.payload.data;
    });
    builder.addCase(getDriverJob.rejected, (state, action) => {
      toast.error("Error fetching job");
    });

    builder.addCase(assignJobToSupervisor.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job assigned successfully");
    });
    builder.addCase(assignJobToSupervisor.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error assigning job");
    });

    builder.addCase(assignJobToDriver.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job assigned successfully");
    });
    builder.addCase(assignJobToDriver.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error assigning job");
    });

    builder.addCase(assignJobToMechanic.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job assigned successfully");
    });
    builder.addCase(assignJobToMechanic.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error assigning job");
    });

    builder.addCase(completedByMechanic.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job completed successfully");
    });
    builder.addCase(completedByMechanic.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error completing job");
    });

    builder.addCase(completedByDriver.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job completed successfully");
    });
    builder.addCase(completedByDriver.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error completing job");
    });

    builder.addCase(approveJob.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job completed successfully");
    });
    builder.addCase(approveJob.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error completing job");
    });

    builder.addCase(
      assignToReceptionistForInvoicing.fulfilled,
      (state, action) => {
        toast.success(action.payload?.message ?? "Job assigned successfully");
      }
    );
    builder.addCase(
      assignToReceptionistForInvoicing.rejected,
      (state, action) => {
        toast.error(action.payload?.message ?? "Error assigning job");
      }
    );

    builder.addCase(cancelPartsOrder.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Parts order cancelled");
    });
    builder.addCase(cancelPartsOrder.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error cancelling parts order");
    });

    builder.addCase(cancelJob.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job discarded successfully");
    });
    builder.addCase(cancelJob.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error discarding job");
    });

    builder.addCase(closeJob.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Job closed successfully");
    });
    builder.addCase(closeJob.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error closing job");
    });

    builder.addCase(getJobLogs.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error fetching job logs");
    });

    builder.addCase(deletePart.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Part deleted successfully");
    });
    builder.addCase(deletePart.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error deleting part");
    });

    builder.addCase(updatePart.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Part updated successfully");
    });
    builder.addCase(updatePart.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error updating part");
    });

    builder.addCase(addPart.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Part added successfully");
    });
    builder.addCase(addPart.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error adding part");
    });

    builder.addCase(addRemarks.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Remarks added successfully");
    });
    builder.addCase(addRemarks.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error adding remarks");
    });

    builder.addCase(updateRemarks.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Remarks updated successfully");
    });
    builder.addCase(updateRemarks.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error updating remarks");
    });

    builder.addCase(addMedia.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Media added successfully");
    });
    builder.addCase(addMedia.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error adding media");
    });

    builder.addCase(deleteMedia.fulfilled, (state, action) => {
      toast.success(action.payload?.message ?? "Media deleted successfully");
    });
    builder.addCase(deleteMedia.rejected, (state, action) => {
      toast.error(action.payload?.message ?? "Error deleting media");
    });

    builder.addCase(markJobAsComplete.fulfilled, (state, action) => {
      const { file, filename } = action.payload;
      processFileDownload(file, 'download', filename);
      toast.success("Job Completed & Invoice generated successfully");
    });
    builder.addCase(markJobAsComplete.rejected, (state, action) => {
      toast.error(action.payload?.message || "Failed to complete job");
    });
  },
});

//eslint-disable-next-line
export const {} = jobsSlice.actions;

export default jobsSlice.reducer;
