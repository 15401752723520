import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography, useTheme } from "@mui/material";
import { JobDetailModal } from "./JobDetailModal";
import {
  capitalizeWords,
  formatStatus,
  getStatusColor,
  truncateText,
} from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { openJobModal } from "../../redux-slices/modalSlice";
import { getMechanics } from "../../redux-slices/usersSlice";
import MechanicSelectModal from "../supervisor/MechanicSelectModal";
import CustomPopover from "./CustomerPopover";
import PrintImg from "../../assets/images/PrintOrderImg.png";
import { getPickupOrdersPdf } from "../../redux-slices/pdfSlice";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#171C36",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: theme.palette.text.primary,
    "& a": {
      color: "#007BFF",
      textDecoration: "underline",
    },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const StaffTable = ({
  jobs = [],
  headerTitles,
  sort,
  tab,
  filter,
  filterOptions,
  setSorting,
  fetchData,
  setAssignedToFilter,
  setAssignedToUser,
  setLimit,
  selectedUser,
  setSelectedUser,
  showDuration = false,
  showDescription = false,
  showAssignedTo = false,
  showStatus = false,
  showInvoiceNumber = false,
}) => {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [headers, setHeaders] = useState(headerTitles);
  const [headerPopOver, setHeaderPopOver] = useState(false);
  const [headerPopOverAnchorEl, setHeaderPopOverAnchorEl] = useState(null);
  const [mechanics, setMechanics] = useState([]);

  const initialOptions = [
    { title: "Anyone", val: "anyone" },
    { title: "Receptionist", val: "receptionist" },
    { title: "Supervisor", val: "supervisor" },
    { title: "Driver", val: "driver" },
    { title: "None", val: "none" },
  ];

  const [popoverOptions, setPopoverOptions] = useState(initialOptions)
  

  useEffect(() => {
    let newHeaders = [...headerTitles];
    if (!showDuration) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Duration in Garage"
      );
    }
    if (!showDescription) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Description"
      );
    }
    if (!showAssignedTo) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Assigned To"
      );
    }
    if (!showStatus) {
      newHeaders = newHeaders.filter((header) => header.title !== "Status");
    }

    if (!showInvoiceNumber) {
      newHeaders = newHeaders.filter(
        (header) => header.title !== "Invoice Number"
      );
    }

    setHeaders(newHeaders);
  }, [
    headerTitles,
    showDuration,
    showDescription,
    showAssignedTo,
    showStatus,
    showInvoiceNumber,
  ]);

  useEffect(()=>{
    if(showAssignedTo){
      dispatch(getMechanics()).then((res)=>{
        let mechanics = Object.values(res.payload).filter(mech => typeof mech !== 'number');
        setMechanics(mechanics)
      })
    }
  }, [])

  useEffect(()=>{
    if(showAssignedTo){
      initialOptions.map((option)=>option.title = option.title !== "Anyone" ? `${option.title} (${jobs?.[2]?.[option.val] ?? 0})` : `${option.title} (${jobs?.[1]?.[filterOptions[filter].val] ?? 0})`)
      setPopoverOptions([
        ...initialOptions.slice(0, 3),
        ...mechanics.map((mechanic)=>({
          title : `${mechanic.name} (Mechanic) (${jobs?.[2][mechanic.name] ?? 0})`,
          val: mechanic.id
        })),
        ...initialOptions.slice(3)
        ]
      )
    }
    //eslint-disable-next-line
  }, [mechanics])

  const openModal = (row) => {
    dispatch(openJobModal({ id: row.id }));
  };

  const handleArrowDownClick = (e, header) => {
    const target = e.target;

    if (header.val === "assigned_to") {
      setHeaderPopOverAnchorEl(target);
      setHeaderPopOver(true);
    } else {
      target.classList.toggle("rotate-180");
      setSorting(header.id);
    }
  };

  const handleHeaderPopOverOptionClick = (option) => {
    setSelectedUser(option.title.split(" ")[0])
    if(typeof option.val === "string"){
      setAssignedToFilter(()=>option.val);
      setAssignedToUser(()=>null)
    }else if(typeof option.val === "number"){
      setAssignedToUser(()=>option.val)
      setAssignedToFilter(()=>null)
    }
    setHeaderPopOver(false);
  };

  const handlePickupOrdersPrint = () => {
    dispatch(getPickupOrdersPdf(0));
  };

  return (
    <div className="mx-7 md:mx-10">
      <div className="flex items-center mb-5">
        <Typography
          className="font-bold text-3xl"
          style={{ color: theme.palette.blue.main }}
        >
          Service Orders
        </Typography>
        {user.roles[0].name === "receptionist" && filter === 2 && (
          <div className="ml-4">
            <img
              src={PrintImg}
              alt="PrintImg"
              className="cursor-pointer"
              onClick={handlePickupOrdersPrint}
            />
          </div>
        )}
      </div>
      <div className="mb-10">
        <TableContainer
          component={Paper}
        >
          <Table sx={{ minWidth:700 }}>
            <TableHead>
              <TableRow>
                {headers?.map((header) => (
                  <StyledTableCell
                    className={header.title === "Order ID" ? "w-28" : "w-48"}
                    key={header.id}
                  >
                    <div
                      className={`flex ${
                        header.title === "Status"
                          ? "justify-start"
                          : "justify-center"
                      }`}
                    >
                      {header.title}
                      {header.title === "Assigned To"
                        ? selectedUser !== "Anyone" && selectedUser !== "None"
                          ? ` (${selectedUser})`
                          : ""
                        : ""}
                      {header.img && (
                        <img
                          src={header.img}
                          className={`ml-2 cursor-pointer ${
                            sort.includes(header.val) ? "rotate-180" : ""
                          }`}
                          alt=""
                          onClick={(e) => handleArrowDownClick(e, header)}
                        />
                      )}
                    </div>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs?.[0]?.map((row) => (
                <StyledTableRow
                  key={row.id}
                  onClick={() => {
                    openModal(row);
                  }}
                  className="cursor-pointer"
                >
                  <StyledTableCell component="td" scope="row" align="center">
                    {row?.display_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.date_time ?? "N/A"}
                  </StyledTableCell>
                  {showDuration && (
                    <StyledTableCell align="center">
                      {row.duration_in_garage ?? "N/A"}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    {row.make_and_model
                      ? capitalizeWords(row.make_and_model)
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.license_number ?? "license number"}
                  </StyledTableCell>
                  {showDescription && (
                    <StyledTableCell align="center">
                      {truncateText(row.description, 4)}
                    </StyledTableCell>
                  )}
                  {showInvoiceNumber && (
                    <StyledTableCell align="center">
                      {row.invoice_number ?? "N/A"}
                    </StyledTableCell>
                  )}
                  {showAssignedTo && (
                    <StyledTableCell align="center">
                      {row.assigned_to ?? "None"}
                    </StyledTableCell>
                  )}
                  {showStatus && (
                    <StyledTableCell align="center">
                      <div className="flex items-center">
                        <div>
                          <div
                            className="w-2 h-2 mr-4 rounded-full"
                            style={{
                              backgroundColor: getStatusColor(row.status),
                            }}
                          />
                        </div>
                        <span>
                          {(user.roles[0].name === "receptionist" ||
                            user.roles[0].name === "supervisor" ||
                            user.roles[0].name === "admin") &&
                          row.status === "reassigned"
                            ? "In Progress"
                            : formatStatus(row.status)}
                        </span>
                      </div>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
              {(!jobs || jobs?.[0] < 1) && (
                <StyledTableRow>
                  <StyledTableCell colSpan={headerTitles.length} align="center">
                    No data available
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {jobs?.[0]?.length < jobs?.[1]?.total_count && (
          <div className="flex justify-end mt-2">
            <Typography
              className="font-bold text-base cursor-pointer"
              style={{ color: theme.palette.blue.main }}
              onClick={() => setLimit((prev) => prev + 20)}
            >
              See more {">>"}
            </Typography>
          </div>
        )}
      </div>
      <div>
        <CustomPopover
          openPopover={headerPopOver}
          setOpenPopover={setHeaderPopOver}
          anchorEl={headerPopOverAnchorEl}
          popoverOptions={popoverOptions}
          handleAction={handleHeaderPopOverOptionClick}
        />
      </div>

      <JobDetailModal tab={tab} fetchData={fetchData} />
      <MechanicSelectModal fetchData={fetchData} />
    </div>
  );
};
