import React from "react";
import { Tabs, Tab, useTheme } from "@mui/material";
import { useSelector } from "react-redux";

export const NavButtons = ({ 
  tabData, 
  currTab, 
  setTab,  
  staffCount,
  serviceCount,
  partCount, 
}) => {
  const theme = useTheme();
  const { jobs } = useSelector((state) => state.jobs);
  const { user } = useSelector((state) => state.auth);
  const jobCounts = jobs?.[1];

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Tabs
      className="border-b-2"
      value={currTab}
      onChange={handleChange}
      aria-label="navigation tabs"
      indicatorColor="primary"
      textColor="inherit"
      variant="scrollable"
      scrollButtons="auto"
    >
      {tabData?.map((item) => (
        <Tab
          className="mx-1 w-42 text-sm"
          key={item.id}
          label={
            <span>
              {item.title}
              {jobCounts && (
                <strong style={{ marginLeft: "5px" }}>
                  {(()=>{
                    // eslint-disable-next-line default-case
                    switch(item.id){
                      case 0:
                        switch(user?.roles[0].name){
                          case "driver":
                            return `(${jobCounts["serviceorders"] ?? 0})`;
                          case "mechanic":
                            return `(${jobCounts["neworders"] ?? 0})`;
                          case "admin":
                            if(window.location.href.split("/")[4] === "settings"){
                              return `(${staffCount ?? 0})`;
                            }
                          default:
                            return `(${jobCounts["total_count"] ?? 0})`;
                        }
                      
                      case 1:
                        switch(user?.roles[0].name){
                          case "mechanic":
                            return `(${jobCounts['reassigned'] ?? 0})`;
                          case "admin":
                            if(window.location.href.split("/")[4] === "settings"){
                              return `(${serviceCount ?? 0})`;
                            }
                          default:
                            return `(${jobCounts['generalorders'] ?? 0})`;
                        }
                      
                      case 2:
                        // eslint-disable-next-line default-case
                        switch(user?.roles[0].name){
                          case "admin":
                              if(window.location.href.split("/")[4] === "settings"){
                                return `(${partCount ?? 0})`;
                              }
                        }
                        break;
                      
                    }
                  })()}
                </strong>
              )}
            </span>
          }
          value={item.id}
          sx={{
            textTransform: "none",
            backgroundColor:
              currTab === item.id
                ? theme.palette.orange.main
                : theme.palette.blue.main,
            color:
              currTab === item.id
                ? theme.palette.blue.main
                : theme.palette.orange.main,
            borderTopRightRadius: "10px",
          }}
        />
      ))}
    </Tabs>
  );
};
