import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  TextField,
  Autocomplete,
} from "@mui/material";
import deleteImg from "../../assets/images/delete.svg";
import addWhiteImg from "../../assets/images/addWhite.svg";
import { deleteService } from "../../redux-slices/invoiceSlice";
import { deletePart } from "../../redux-slices/jobsSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import ConfirmDialog from "./ConfirmDialog";

const InvoicingTable = ({
  isPartsTable = false,
  data,
  setData,
  total,
  setTotal,
  services = [],
  parts = [],
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    console.log(data)
  }, [])
  

  const handleChange = (index, field, value) => {
    const newItems = [...data];
    const numericFields = ["quantity", "unit_price", "cost_price"];
    if (numericFields.includes(field)) {
      value = value === "" ? "" : parseFloat(value) || 0;
      if (value < 0) return;
    }
    newItems[index][field] = value;
    if (field === "quantity" || field === "unit_price") {
      const quantity = parseFloat(newItems[index].quantity) || 0;
      const unitPrice = parseFloat(newItems[index].unit_price) || 0;
      newItems[index].amount = parseFloat((quantity * unitPrice).toFixed(2));
    }
    setData(newItems);
  };

  const handleServiceChange = (index, serviceId) => {
    if(!serviceId){
      const newItems = [...data];
      newItems[index] = {
        id: "",
        code: "",
        description: "",
        unit_price: "",
        quantity: 1,
        amount: "0.00",
      };
      setData(newItems);
      return;
    }
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    if (selectedService) {
      const newItems = [...data];
      newItems[index] = {
        ...newItems[index],
        id: selectedService.id,
        code: selectedService.name, // Use name as the code
        description: selectedService.description,
        unit_price: selectedService.unit_price,
        quantity: newItems[index].quantity || 1,
        amount: (
          (newItems[index].quantity || 1) * selectedService.unit_price
        ).toFixed(2),
      };
      setData(newItems);
    }
  };

  const handlePartChange = (index, partObject) => {
    if (typeof partObject === "string") {
      const newItems = [...data];
      newItems[index] = {
        ...newItems[index],
        name: partObject,
      };
      setData(newItems);
      return;
    }
    if(!partObject){
      const newItems = [...data];
      newItems[index] = {
        id: "",
        name: "",
        description: "",
        unit_price: "",
        cost_price: "",
        quantity: 1,
        amount: "0.00",
      };
      setData(newItems);
      return;
    }
    const selectedPart = parts.find((part) => part.id === partObject.id);
    if (selectedPart) {
      const newItems = [...data];
      newItems[index] = {
        ...newItems[index],
        id: selectedPart.id,
        name: selectedPart.name,
        description: selectedPart.description,
        unit_price: selectedPart.unit_price,
        cost_price: selectedPart.cost_price || "", // If cost_price exists in your Part model
        quantity: newItems[index].quantity || 1,
        amount: (
          (newItems[index].quantity || 1) * selectedPart.unit_price
        ).toFixed(2),
      };
      setData(newItems);
    }
  };

  const insertRow = () => {
    if (isPartsTable) {
      setData([
        ...data,
        {
          id: "",
          name: "",
          quantity: 1,
          cost_price: "",
          unit_price: "",
          amount: 0,
        },
      ]);
    } else {
      setData([
        ...data,
        {
          id: "",
          code: "",
          description: "",
          quantity: 1,
          unit_price: "",
          amount: 0,
        },
      ]);
    }
  };

  const openConfirmDialog = (index) => {
    setDeleteIndex(index);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    const index = deleteIndex;
    const item = data[index];
    const newItems = [...data];
    if(!isPartsTable){
      newItems.splice(index, 1);
      setData(newItems);
      setConfirmOpen(false);
      setDeleteIndex(null);
      return;
    }
    console.log(item)
    if (item.job_id) {
      const deleteAction = isPartsTable ? deletePart : deleteService;
      try {
        await dispatch(deleteAction(item.id)).unwrap();
        newItems.splice(index, 1);
        setData(newItems);
        toast.success(
          `${isPartsTable ? "Part" : "Service"} deleted successfully`
        );
      } catch (error) {
        toast.error(
          `Failed to delete ${isPartsTable ? "part" : "service"}: ${
            error.message
          }`
        );
      }
    } else {
      newItems.splice(index, 1);
      setData(newItems);
      toast.success(
        `${isPartsTable ? "Part" : "Service"} deleted successfully`
      );
    }
    setConfirmOpen(false);
    setDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setConfirmOpen(false);
    setDeleteIndex(null);
  };

  useEffect(() => {
    const updatedData = data.map((item) => {
      const quantity = parseFloat(item.quantity) || 0;
      const unitPrice = parseFloat(item.unit_price) || 0;
      const amount = parseFloat((quantity * unitPrice).toFixed(2)) || 0;
      return { ...item, quantity, unit_price: unitPrice, amount };
    });
    setData(updatedData);
  }, [data.length]);

  useEffect(() => {
    const calculatedTotal = data.reduce((sum, item) => {
      const amount = parseFloat(item.amount) || 0;
      return sum + amount;
    }, 0);
    setTotal(parseFloat(calculatedTotal.toFixed(2)));
  }, [data, setTotal]);

  return (
    <div>
      <Typography
        className="mt-4 mb-2 font-bold text-sm"
        style={{ color: theme.palette.text.darkBlue }}
      >
        {isPartsTable ? "Parts Used" : "Services Done"}:
      </Typography>
      <TableContainer
        sx={{ borderRadius: 2 }}
        className="mt-4"
        component={Paper}
      >
        <Table sx={{ maxWidth: "100%" }}>
          <TableHead style={{ backgroundColor: theme.palette.orange.main }}>
            <TableRow>
              <TableCell className="text-white">
                {isPartsTable ? "Product/Part" : "Service Code"}
              </TableCell>
              <TableCell className="text-white">Description</TableCell>
              <TableCell className="text-white w-[5%]">Quantity</TableCell>
              {isPartsTable && (
                <TableCell className="text-white">Cost Price (ANG)</TableCell>
              )}
              <TableCell className="text-white">Unit Price (ANG)</TableCell>
              <TableCell className="text-white">Amount (ANG)</TableCell>
              <TableCell className="text-center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: 40, // Set explicit row height
                  }}
                >
                  <TableCell
                    className="px-4"
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding (0.5rem = 8px)
                  >
                    {isPartsTable ? (
                      <Autocomplete
                        freeSolo
                        options={parts}
                        getOptionLabel={(option) => option.name || ""}
                        value={
                          parts.find(
                            (part) => part.name === data[index]?.name
                          ) ||
                          (data[index]?.name
                            ? { name: data[index]?.name }
                            : null)
                        }
                        onInputChange={(event, newInputValue) => {
                          handlePartChange(index, newInputValue);
                        }}
                        onChange={(event, newValue) => {
                          handlePartChange(index, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small" // Reduce TextField height
                            InputProps={{
                              ...params.InputProps,
                              sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                            }}
                          />
                        )}
                      />
                    ) : (
                      <Autocomplete
                        forcePopupIcon={false}
                        options={services}
                        getOptionLabel={(option) => option.name || ""}
                        value={
                          services.find(
                            (service) => service.name === data[index]?.code
                          ) || null
                        }
                        onChange={(event, newValue) =>
                          handleServiceChange(index, newValue?.id)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small" // Reduce TextField height
                            InputProps={{
                              ...params.InputProps,
                              sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                            }}
                          />
                        )}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    className="px-4"
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding
                  >
                    <TextField
                      value={item.description || ""}
                      className="w-full text-sm"
                      onChange={(e) =>
                        handleChange(index, "description", e.target.value)
                      }
                      disabled={!isPartsTable}
                      variant="outlined"
                      size="small" // Reduce TextField height
                      InputProps={{
                        sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className="px-4"
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding
                  >
                    <TextField
                      className="w-full text-center text-sm"
                      type="number"
                      value={item.quantity}
                      onChange={(e) =>
                        handleChange(index, "quantity", e.target.value)
                      }
                      placeholder="Qty"
                      inputProps={{ min: 1 }}
                      variant="outlined"
                      size="small" // Reduce TextField height
                      InputProps={{
                        sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                      }}
                    />
                  </TableCell>
                  {isPartsTable && (
                    <TableCell
                      className="px-4"
                      sx={{ paddingY: 0.5 }} // Reduce vertical padding
                    >
                      <TextField
                        className="w-full text-left text-sm"
                        type="number"
                        value={item.cost_price || ""}
                        onChange={(e) =>
                          handleChange(index, "cost_price", e.target.value)
                        }
                        placeholder="Cost Price"
                        disabled={!isPartsTable}
                        variant="outlined"
                        size="small" // Reduce TextField height
                        InputProps={{
                          sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell
                    className="px-4"
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding
                  >
                    <TextField
                      className="w-full text-left text-sm"
                      type="number"
                      value={item.unit_price || ""}
                      onChange={(e) =>
                        handleChange(index, "unit_price", e.target.value)
                      }
                      placeholder="Unit Price"
                      disabled={!isPartsTable}
                      variant="outlined"
                      size="small" // Reduce TextField height
                      InputProps={{
                        sx: { fontSize: "0.875rem", paddingY: 0.25 }, // Match text-sm, reduce padding
                      }}
                    />
                  </TableCell>
                  <TableCell
                    className="px-4 text-left font-normal text-sm"
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding
                  >
                    {item.amount || 0}
                  </TableCell>
                  <TableCell
                    sx={{ paddingY: 0.5 }} // Reduce vertical padding
                  >
                    <div className="flex justify-center">
                      <img
                        className="cursor-pointer min-w-4"
                        src={deleteImg}
                        alt="Delete"
                        onClick={() => openConfirmDialog(index)}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow sx={{ height: 40 }}>
              {" "}
              {/* Reduced from 50px to 40px */}
              <TableCell colSpan={isPartsTable ? 6 : 7}>
                <div className="flex justify-center">
                  <img
                    className="cursor-pointer"
                    onClick={insertRow}
                    src={addWhiteImg}
                    alt="Add"
                  />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        className="mt-4 mb-2 font-bold text-sm text-end"
        style={{ color: theme.palette.text.darkBlue }}
      >
        {isPartsTable ? "Parts Total" : "Services Total"}: {total} ANG
      </Typography>
      <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        text={`Are you sure you want to delete this ${
          isPartsTable ? "part" : "service"
        }`}
      />
    </div>
  );
};

export default InvoicingTable;
